<template>
    <div class="w-100 text-center">
        <!-- image -->
        <b-img
            fluid
            :src="imgUrl"
            alt="Error page"
        />
        <h1 class="mt-4 font-weight-bolder">
            서버와 연결할 수 없습니다.
        </h1>

        <b-progress :value="count" :max="100" show-progress animated variant="info"></b-progress>
        <h4>
            잠시만 기다려주세요...
        </h4>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton, BProgress } from 'bootstrap-vue'
  import store from '@/store/index'
  
  export default {
    components: {
      BLink, BImg, BButton, BProgress
    },
    data() {
      return {
        count: 0,
        intervalId:  null,
        downImg: require('@/assets/images/pages/processing.png'),
      }
    },
    created() {
      this.intervalId = setInterval(() => {
        if(this.count === 100) this.count = 0
        else this.count = this.count + 10
        
      }, 1000);
    },
    beforeDestroy() {
      this.count = 100
    },
    destroyed() {
      clearInterval(this.intervalId)
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.downImg = require('@/assets/images/pages/500.png')
          return this.downImg
        }
        return this.downImg
      },
    },
    methods: {
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  </style>
  