<template>
    <div>
        <div v-if="connect === true">
            <div style="border: groove; border-radius: 0.3rem; background-color: #E7ECF4; font-size: large;">
                
                <table
                    style="width: -webkit-fill-available; margin-top: 0.1rem; font-size: large;">
                    <tr>
                        <th colspan="2" style="color:black"> 동작 모니터 </th>
                    </tr>
                    <tr style="border-bottom: 1px solid;">
                        <td style="text-align:left">
                            현재 상태: 
                        </td>
                        <td v-if="plc.state === null">
                            <b-spinner variant="warning" small label="Spinning" ></b-spinner>
                        </td>
                        <td v-else>
                            <b>{{ (plc.state)? '동작중': '대기중' }}</b>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid;">
                        <td style="text-align:left">
                            1일 횟수: 
                        </td>
                        <td v-if="plc.count === null">
                            <b-spinner variant="warning" small label="Spinning" ></b-spinner>
                        </td>
                        <td v-else><b>{{ plc.count }}</b> 회</td>
                    </tr>
                </table>
            </div>
            <div style="margin-top: 0.3rem; padding: 0.1rem; border: groove; border-radius: 0.3rem; background-color: #E7ECF4;">
                <table
                    style="width: -webkit-fill-available; margin-top: 0.1rem; font-size: large;">
                    <tr>
                        <th colspan="4" style="color:black"> 센서 모니터 </th>
                    </tr>
                    <tr
                        v-for="sensor of plc.sensors"
                        :key="sensor.type"
                        style="border-bottom: 1px solid;"
                    >
                        <td style="text-align:left">
                            {{ sensor.name }}:
                        </td>
                        <td v-if="sensor.val === null">
                            <b-spinner variant="warning" small label="Spinning" ></b-spinner>
                        </td>
                        <td v-else>
                            <b> {{ sensor.val }} </b> {{ sensor.unit }}/
                        </td>
                        <td style="text-align:left"> {{(sensor.type === 'temp')? '최고 온도 :': '평균 :'}} </td>
                        <td v-if="sensor.avg === null || sensor.max === null">
                            <b-spinner variant="warning" small label="Spinning" ></b-spinner>
                        </td>
                        <td v-else>
                            <b> {{ (sensor.type === 'temp')? sensor.max: sensor.avg }} </b> {{ sensor.unit }}
                        </td>
                    </tr>
                </table>

            
            </div> 
            <div style="margin-top: 0.3rem; padding: 0.1rem; border: groove; border-radius: 0.3rem; background-color: #E7ECF4;">
                <table
                    style="width: -webkit-fill-available; margin-top: 0.1rem; font-size: large;">
                    <tr>
                        <th colspan="2" style="color:black"> 전력 모니터 </th>
                    </tr>
                    <tr
                    v-for="power of plc.power"
                    :key="power.type"
                    style="border-bottom: 1px solid"
                    >
                        <td style="text-align:left">
                            {{ power.name }}:
                        </td>
                        <td v-if="power.val === null">
                            <b-spinner variant="warning" small label="Spinning" ></b-spinner>
                        </td>
                        <td v-else >
                            <b> {{ power.val }} </b> {{ power.unit }}
                        </td>
                    </tr>
                </table>
                <div v-if="!refreshBtn">
                    <b-button
                    style="width: -webkit-fill-available; font-size:x-large"
                    size="sm"
                    @click="initPlcMode"
                    variant="info"
                    :pressed="refreshBtn"
                    :disabled="refreshBtn"
                    >
                        <feather-icon
                        icon="RefreshCwIcon"
                        size="24"/>
                        <span> 데이터 갱신</span>
                    </b-button>
                    
                </div>
            </div> 
        </div>
        
        <div v-if="connect === null" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <b-spinner variant="danger" label="Spinning" class="mt-1"></b-spinner>
                <br><br>
                <b>잠시만 기다려주세요...</b> 
            </h3>
        </div>

        <div v-if="connect === 'error'" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <feather-icon
                icon="AlertTriangleIcon"
                style="color:red;"
                size="30"/>
                <br><br>
                <b>장비 접속이 원활하지 않습니다.</b>
            </h3>
            <br>
            <b-button variant="warning" @click="initPlcMode">재연결</b-button>
        </div>
    </div>
    
</template>

<script>
import { 
    BButtonGroup,
    BButton,
    BTable,
    BSpinner,
} from 'bootstrap-vue'
import store from '@/store'

export default {
    components: {
        BSpinner,
        BButtonGroup,
        BButton,
        BTable
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    created() {
        this.initPlcMode()
    },
    data() {
        return {
            refreshBtn: false,
            connect: null,
            plc: {
                mode: 'Main',
                state: null,
                count: null,
                sensors: [
                    {   
                        name: '온도',
                        type: 'temp',
                        val: null,
                        max: null,
                        unit: '℃'
                    },
                    {
                        name: '황화수소',
                        type: 'h2s',
                        val: null,
                        avg: null,
                        unit: 'ppm'
                    },
                    {
                        name: '암모니아',
                        type: 'nh3',
                        val: null,
                        avg: null,
                        unit: 'ppm'
                    },
                    {
                        name: '무게',
                        type: 'loadCell',
                        val: null,
                        avg: null,
                        unit: 'Kg'
                    },
                ],
                power: [
                    {
                        name: '현재값',
                        type: 'kWatt',
                        val: null,
                        unit: 'Kw'
                    },
                    {
                        name: '1일 누적값',
                        type: 'kWattAvg',
                        val: null,
                        unit: 'Kw'
                    },
                ],
            }
        }
    },
    methods: {
        async initPlcMode(mode) {
            this.refreshBtn = true
            this.connect = null
            try {
                this.plc.state = null
                const response = await store.dispatch('button/getState', { id: this.buttonItem._id })
                if(response.data.status === 'fail') return this.connect = false
                const state = response.data.data
                this.plc.state = state['state']
                this.plc.count = state['count']
                this.plc.sensors[0].val = state['temperature']
                this.plc.sensors[1].val = state['nh3']     
                this.plc.sensors[2].val = state['hs2']
                this.plc.sensors[3].val = state['weight']        
                this.plc.sensors[0].max = state['max_temperature']
                this.plc.sensors[1].avg = state['avg_nh3']
                this.plc.sensors[2].avg = state['avg_hs2']   
                this.plc.sensors[3].avg = state['avg_weight']
                this.plc.power[0].val = state['power']
                this.plc.power[1].val = state['acc_power']
                this.refreshBtn = false
                return this.connect = true
            } catch (error) {
                return this.connect = 'error'
            
            }
            
            

        }
    },
}

</script>

