<template>
    <div style="margin-top: 0.2rem; padding: 0.5rem; border: groove; border-radius: 0.5rem; background-color: #E7ECF4;">
        <div v-show="connect === null" class="mt-1">
          <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
              <b-spinner variant="danger" label="Spinning" class="mt-1"></b-spinner>
              <br><br>
              <b>잠시만 기다려주세요...</b>
          </h3>
        </div>
        <div v-show="connect === false" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <h4>접속 불가</h4><hr>
                <feather-icon
                    icon="AlertTriangleIcon"
                    style="color:red;"
                    size="45"/>
                <hr>
                <b>점검 사항</b>
                <hr>
                <p>1) 장비 전원</p>
                <p>2) 인터넷 연결</p>
                <p>3) 네트워크 라인</p>
            </h3>
            <br>
            <b-button variant="info" @click="init">재연결</b-button>
            <hr>
        </div>
        <div v-if="connect">
          <table 
              class="tableStyle" style="font-size: x-large">
                <tr>
                  <th colspan="4" 
                  @click="init(1)"
                  :class="`title ${invNo == 1? 'active': ''}`">
                    INV1
                  </th>
                  <th colspan="4" v-show="inv2"
                  @click="init(2)"
                  :class="`title ${invNo == 2? 'active': ''}`">
                    INV2
                  </th>
                </tr>
                <tr v-if="remote === 'Remote'">
                  <th colspan="3" 
                  @click="setPage(1)"
                  :class="`title ${page === 1? 'active': ''}`">
                    메인
                  </th>
                  <th colspan="3" 
                  @click="setPage(2)"
                  :class="`title ${page === 2? 'active': ''}`">
                    자동
                  </th>
                  <th colspan="3" 
                  @click="setPage(3)"
                  :class="`title ${page === 3? 'active': ''}`">
                    관리
                  </th>
                </tr>
            </table>
            <div v-if="page === 1">
              <table class="tableStyle" >
                <tr >
                  <th colspan="4" class="thStyle">
                    운전 모드 
                  </th>
                  <th colspan="4" class="thStyle">
                    현재 상태
                  </th>
                </tr>
                <tr class="thStyle">
                  <td v-show="remote === 'Local'"
                  colspan="4" class="thStyle">
                    로컬
                  </td>
                  <td v-show="remote === 'Remote'"
                  colspan="4" 
                  @click="setCommand(`${invNo}ChAuto`, !auto), getBtnState()"
                  :class="`btnStyle ${auto? 'active': ''}`" >
                    {{ (auto)?'자동': '수동' }}
                  </td>
                  <td colspan="4" 
                  class="thStyle" 
                  :style="`color: ${(status.run === 'Run')? 'yellow':(status.run === 'Err')? 'red': 'black'}; font-weight: bold`">
                    {{ (status.run === 'Run')? '운전중':(status.run === 'Err')? '에러': '정지' }}
                  </td>
                </tr>
                <tr v-show="remote === 'Local'">
                  <th class="thStyle active" colspan="9">
                    원격 사용 불가
                  </th>
                </tr>
              </table>
              <div v-if="remote === 'Remote'">
                <table 
                  v-if="!auto" 
                  class="tableStyle">
                  <tr>
                    <th colspan="8" class="thStyle">
                      수동 운전
                    </th>
                  </tr>
                  <tr>
                    <td colspan="4"
                    style="width: 50%"
                    @click="setCommand(`${invNo}ChManuRun`, !manual.run)"
                      :class="`btnStyle ${manual.run? 'active': ''}`" >
                      {{ (manual.run)?'운전': '정지' }}
                    </td>
                    <td 
                    colspan="4" 
                    @click="setFrequencyByModal(`Q${invNo}ChManuFrq`, manual.frequency)"
                    style="width: 50%" 
                    class="btnStyle">
                      <b class="targetVal">{{ frequency2Percentage(manual.frequency)}} ％</b>
                    </td>
                  </tr>
                </table>
                <table 
                  v-if="auto" 
                  class="tableStyle">
                    <tr>
                      <th colspan="8" class="thStyle">
                        자동 운전
                      </th>
                    </tr>
                    <tr>
                      <td colspan="3" class="btnStyle"
                      @click="setCommand(`${invNo}ChActModeTemp`, !autoMode['Temp'])"
                      :class="`btnStyle ${autoMode['Temp']? 'active': ''}`" >
                        온도 제어 <br> ({{ autoMode['Temp']?'ON': 'OFF' }})
                      </td>
                      <td colspan="3" class="btnStyle"
                      @click="setCommand(`${invNo}ChActModeDate`, !autoMode['Date'])"
                      :class="`btnStyle ${autoMode['Date']? 'active': ''}`" >
                        시간 제어 <br> ({{ autoMode['Date']?'ON': 'OFF' }})
                      </td>
                      <td colspan="3" class="btnStyle"
                      @click="setCommand(`${invNo}ChActModeInterval`, !autoMode['Interval'])"
                      :class="`btnStyle ${autoMode['Interval']? 'active': ''}`" >
                        주기 제어 <br> ({{ autoMode['Interval']?'ON': 'OFF' }})
                      </td>
                    </tr>
                </table>
              </div>
              <table class="tableStyle">
                  <tr>
                    <th colspan="9" class="thStyle">
                      시스템 정보
                    </th>
                  </tr>
                  <tr>
                    <td class="thStyle" style="width: 50%">
                      <vue-apex-charts
                        ref="charti2"
                        type="bar"
                        height="150"
                        :options="tempOptions"
                        :series="tempSeries"
                      />
                    </td>
                    <td class="thStyle" style="width: 50%">
                      <vue-apex-charts
                        ref="charti"
                        type="donut"
                        height="160"
                        :options="powerOptions"
                        :series="powerSeries"
                      />
                    </td>
                  </tr>
              </table>
              <table 
              v-show="status.frequency || status.current || status.volt"
              class="tableStyle">
                <tr>
                  <th colspan="3" class="thStyle">
                    출력 주파수
                  </th>
                  <th colspan="3" class="thStyle">
                    출력 전류
                  </th>
                  <th colspan="3" class="thStyle">
                    출력 전압
                  </th>
                </tr>
                <tr style="color: blue;">
                  <th colspan="3" class="thStyle">
                    {{status.frequency}} Hz
                  </th>
                  <th colspan="3" class="thStyle">
                    {{status.current}} A
                  </th>
                  <th colspan="3" class="thStyle">
                    {{status.volt}} V
                  </th>
                </tr>
              </table>
            </div>
            <div v-if="page === 2">
              <table class="tableStyle">
                <colgroup>
                  <col style="width: 65%">
                  <col style="width: 35%">
                </colgroup>
                <tr >
                  <th colspan="3" class="thStyle">자동 운전 설정 (온도 모드)</th>
                </tr>
                <tr class="btnStyle" >
                  <td rowspan="2" 
                  style="padding: 1rem;">
                    시작 대기: 
                    <b 
                    @click="setValByModal(`Q${invNo}ChWorkDelayTimer`, autoSet.temp.WDTimer)"
                    class="targetVal">
                      {{ autoSet.temp.WDTimer }} 분
                    </b><hr>
                    정지 대기: 
                    <b 
                    @click="setValByModal(`Q${invNo}ChStopDelayTimer`, autoSet.temp.SDTimer)"
                    class="targetVal">
                      {{autoSet.temp.SDTimer }} 분
                    </b>
                  </td>
                  <td rowspan="2" 
                  @click="setValByModal(`Q${invNo}ChTargetTemp`, autoSet.temp.targetTemp)"
                  style="border-left: 3px inset"> 
                    설정 온도 <br><br> 
                    <b class="targetVal">
                      {{ autoSet.temp.targetTemp }} ℃
                    </b>
                  </td>
                </tr>
              </table>
              <table class="tableStyle">
                <colgroup>
                  <col style="width: 65%">
                  <col style="width: 35%">
                </colgroup>
                <tr >
                  <th colspan="3" class="thStyle">자동 운전 설정 (주기 모드)</th>
                </tr>
                <tr class="btnStyle" >
                  <td rowspan="2" style="padding: 1rem;">
                    운전 시간: 
                    <b 
                    @click="setValByModal(`Q${invNo}ChWorkTimer`, autoSet.interval.WTimer)"
                    class="targetVal">
                      {{ autoSet.interval.WTimer }} 분
                    </b><hr>
                    정지 시간: 
                    <b 
                    @click="setValByModal(`Q${invNo}ChStopTimer`, autoSet.interval.STimer)"
                    class="targetVal">
                      {{ autoSet.interval.STimer }} 분
                    </b>
                  </td>
                  <td 
                  @click="setFrequencyByModal(`Q${invNo}ChTargetFrq`, autoSet.interval.targetFrq)"
                  rowspan="2" style="border-left: 3px inset"> 
                    가동률 <br><br>
                    <b class="targetVal">
                      {{ frequency2Percentage(autoSet.interval.targetFrq) }} ％
                    </b>
                  </td>
                </tr>
              </table>
              <table class="tableStyle">
                <tr >
                  <th colspan="5" class="thStyle">자동 운전 설정 (시간 모드)</th>
                </tr>
                <tr class="thStyle" >
                  <td>
                    사용
                  </td>
                  <td>
                    운전 시작
                  </td>
                  <td>
                    운전 정지
                  </td>
                  <td>
                    가동률
                  </td>
                </tr>
                <tr class="btnStyle" v-for="time of autoSet.timeList" :key="time.no">
                  <th>
                    <b-form-checkbox 
                      v-model="time.active" 
                      @change="setCommand(`${invNo}ChSche${time.no}Able`, time.active? 1: 0)"
                      switch>
                    </b-form-checkbox>
                  </th>
                  <td @click="setValByTimeModal(`Q${invNo}ChSche${time.no}S`, time)">
                    <b class="targetVal">
                      {{ time.start }}
                    </b> 
                  </td>
                  
                  <td @click="setValByTimeModal(`Q${invNo}ChSche${time.no}E`, time)">
                    <b class="targetVal">
                      {{ time.end }}
                    </b>
                  </td>
                  <td >
                    <b @click="setFrequencyByModal(`Q${invNo}ChSche${time.no}Frq`, time.frequency * 100)"
                    class="targetVal">
                      {{ frequency2Percentage(time.frequency) }} ％
                    </b>
                  </td>
                </tr>
              </table>
            </div>
            <table class="tableStyle" v-if="page === 3">
                <!-- <tr class="btnStyle" colspan="8">
                  <th>
                    ACC 작동 시간: 
                    <b 
                    @click="setValByModal(`Q${invNo}ChAccTime`, manageSet.acc)"
                    class="targetVal">
                      {{ manageSet.acc }} 초
                    </b>
                  </th>
                </tr>
                <tr class="btnStyle" >
                  <th>
                    DEC 정지 시간: 
                    <b 
                    @click="setValByModal(`Q${invNo}ChDecTime`, manageSet.dec)"
                    class="targetVal">
                      {{ manageSet.dec }} 초
                    </b>
                  </th>
                </tr> -->
                <tr class="btnStyle">
                  <th>
                    최소 가동률: 
                    <b 
                    @click="setFrequencyByModal(`Q${invNo}ChMinFrq`, manageSet.minFrq)"
                    class="targetVal">
                      {{ frequency2Percentage(manageSet.minFrq) }} ％
                    </b>
                  </th>          
                </tr>
                <tr class="btnStyle" >
                  <th>
                    최대 가동률: 
                    <b 
                    @click="setFrequencyByModal(`Q${invNo}ChMaxFrq`, manageSet.maxFrq)"
                    class="targetVal">
                      {{ frequency2Percentage(manageSet.maxFrq) }} ％
                    </b>
                  </th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { 
    BButtonGroup,
    BButton,
    BTable,
    BSpinner,
    BFormCheckbox,
    BBadge,
    BInputGroup,
    BFormInput,
    BModal
} from 'bootstrap-vue'
import store from '@/store'
import VueSlider from 'vue-slider-component'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
    components: {
        VueSlider,
        BSpinner,
        BButtonGroup,
        BButton,
        BTable,
        BFormCheckbox,
        BInputGroup,
        BFormInput,
        BBadge,
        BModal,
        VueTimepicker,
        VueApexCharts: () => import('vue-apexcharts'),
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
          connect: false, // device connect
          remote: false, // Remote Mode
          inv2: false, // Inv2 Able
          page: 1,
          invNo: 1,
          auto: false,
          manual: {
            frequency: 0,
            run: false
          },
          autoMode: {
            Temp: false,
            Date: false,
            Interval: false
          },
          status: {
            run: false,
            temperature: 0,
            current: 0,
            frequency: 0,
            volt: 0
          },
          autoSet: {},
          manageSet: {},
          intervalId: null,
          timeoutId: null,
          tempOptions: {
            tooltip: {
              enabled : false
            },
            legend: {
              show: false
            },
            title: {
              text: '현재 온도 모니터'
            },
            colors: ['#DC143C'],
            xaxis: {
              labels: {
                show: false,
              },
              categories: ['현재 온도'],
            },
            chart: {
              type: 'bar',
              stacked: true
            },
            dataLabels: {
              
              style: {
                fontSize: '2rem',
                colors: ['blue'],
              },
              formatter: v => v + ' ℃'
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                horizontal: false,
                columnWidth: '25%',
                endingShape: 'rounded',
                distributed: true,
                dataLabels: {
                  position: 'top'
                }
              },
            },
            stroke: {
              show: false,
              width: 2,
              curve: 'smooth',
            },
            yaxis: {
              labels: {
                show: true,
              },
              opposite: false,
              max: 70,
              min: 0,
              tickAmount: 7,
            },
            grid: {
              show: true
            }
          },
          tempSeries: [
            {
              name: '온도',
              data: [0]
            },
          ],
          powerOptions: {
            tooltip: {
              enabled : false
            },
            title: {
              text: '현재 출력 모니터'
            },
            noData: {
              text: 'No Data'
            },
            legend: {
              show: false
            },
            plotOptions: {
              pie: {
                size: '65%',
                customScale: 1,
                expandOnClick: false,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: false,
                    },
                    value: {
                      fontSize: '2rem',
                      color: 'blue'
                    },
                    total: {
                      show: true,
                      showAlways: true,
                      formatter: v => v.globals.seriesTotals[0] + ' ％'
                    }
                  }
                },
              }
            },
            colors: ['#4354FF', '#D5D5D5'],
            dataLabels: {
              enabled: false,
              formatter: v => v + " ％"
            },
            labels: [
              '현재 출력'
            ],
          },
          powerSeries: [0, 100],
        }
    },
    created() {
        this.init()
        
    },
    destroyed() {
      this.clearStream()
    },
    methods: {
        async clearStream () {
          clearInterval(this.intervalId)
          clearTimeout(this.timeoutId)
        },
        async init (invNo) {
          if(invNo) this.invNo = invNo
          this.connect = null
          await this.getMode()
          await this.getBtnState()
          this.page = 1
          //await this.getStatus()
          this.getIntervalStreamData()
          this.connect = true
        },
        async getMode () {
          try {
            const res = await this.getCommand('init')
            const {inv2, remote} = res
            this.inv2 = inv2
            this.remote = remote
            return true
          } catch (error) {
            this.connect = false
          }
          
        },
        async getBtnState () {
          const data = await this.getCommand(`${this.invNo}mode`)
          this.auto = data.auto
          if(data.auto && data.actAtMode !== '') this.autoMode[data.actAtMode] = true, console.log(this.autoMode)
          if(!data.auto) this.manual.run = data.run, this.manual.frequency = data.frequency
          return
        },
        async getStatus() {
          const status = await this.getCommand(`${this.invNo}status`)
          if(status.connect) delete status.connect
          return this.status = status, this.showChart();
        },
        async getAutoSet () {
          this.autoSet = await this.getCommand(`${this.invNo}auto`)
          this.connect = true
        },
        async getManageSet () {
          this.manageSet = await this.getCommand(`${this.invNo}manage`)
          this.connect = true
        },
        async getIntervalStreamData () {
          const exitIntervalMinute = 3
          this.clearStream()
          this.getStatus()
          this.timeoutId = setTimeout(() => {
            clearInterval(this.intervalId)
          }, 1000 * 60 *  exitIntervalMinute);
          this.intervalId = setInterval(() => this.getStatus(), 3500);
          return ;
        },
        async getCommand (command) {
            const res = await store.dispatch('button/getStateByCommand', 
              { id: this.buttonItem._id, command})
            const rData = res.data
            if(rData.status === 'success' && rData.data.connect) return rData.data
            throw Error(`Command ${command} Err`)
        },
        frequency2Percentage (frq) {
          return (frq === 60)? 100:parseInt(((frq * 100) / 60).toString().substring(0, 2))
        },
        percentage2Frequecy (method, percentage) {
          return method === 'set'? parseInt(percentage) * 60: parseInt(percentage * 0.01)
        },
        async setCommand(command, commandValue) {
          this.clearStream()
          const param = {
                command,
                commandValue
            }
            try {
                const result = await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
                if(result.data !== 'fail') {
                  if(this.page === 1) this.getIntervalStreamData()
                  return this.activateState(command, commandValue)
                }
                throw new Error()
            } catch (error) {
                return '응답 없음'
            }
        },
        async setPage (page) {
          this.connect = null
          this.clearStream()
          if(page === 1) this.init()
          if(page === 2) this.getAutoSet()
          if(page === 3) this.getManageSet()
          return this.page = page
        },
        showChart() {
          const percentage = this.frequency2Percentage(this.status.frequency)
          this.$refs['charti'].updateSeries([percentage, 100 - percentage])
          this.$refs['charti2'].updateSeries([
            {name: '온도',
            data: [this.status.temperature]}
          ])
        },
        activateState(command, commandValue) {
          if(this.selectMode === 'main') this.getIntervalStream()
          if(command.includes('ChAuto')) return this.auto = commandValue
          if(command.includes('ChManuRun')) return this.manual.run = commandValue
          if(command.includes('ChManuFrq')) return this.manual.frequency = parseInt(commandValue * 0.01)
          if(command.includes('ChActMode')) {
            this.autoMode.Temp = false, this.autoMode.Date = false, this.autoMode.Interval = false
            if(commandValue) this.autoMode[command.split('Mode')[1]] = commandValue
            return; //this.autoMode = (commandValue == 0)? '':command.split('Mode')[1]
          }
          if(command.includes('ChWorkDelayTimer')) return this.autoSet.temp.WDTimer = commandValue
          if(command.includes('ChStopDelayTimer')) return this.autoSet.temp.SDTimer = commandValue
          if(command.includes('ChTargetTemp')) return this.autoSet.temp.targetTemp = commandValue
          if(command.includes('ChWorkTimer')) return this.autoSet.interval.WTimer = commandValue
          if(command.includes('ChStopTimer')) return this.autoSet.interval.STimer = commandValue
          if(command.includes('ChTargetFrq')) return this.autoSet.interval.targetFrq = commandValue
          if(command.includes('ChAccTime')) return this.manageSet.acc = parseInt(commandValue * 0.1)
          if(command.includes('ChDecTime')) return this.manageSet.dec = parseInt(commandValue * 0.1)
          if(command.includes('Sche') && command.includes('Frq')) {
            console.log('Val : ', this.buttonItem.type, commandValue)
            if(commandValue % 60 > 0) commandValue = commandValue / 10, console.log(2, commandValue)
            return this.autoSet.timeList[command[8] - 1].frequency = this.frequency2Percentage(commandValue * (6 / 10))
          }
          if(command.includes('ChMinFrq')) return this.manageSet.minFrq = commandValue
          if(command.includes('ChMaxFrq')) return this.manageSet.maxFrq = commandValue
          return;
        },
        async setFrequencyByModal(command, val) {
          const content = this.getContentList(command, val)
          let value = 0
          value = this.frequency2Percentage(val) 
          this.$bvModal.msgBoxConfirm(<h4> 
              <vueSlider
                vModel={value}
                class="b-1 mt-2 text"
                lazy={true}
                max={100}
                min={0}
                interval={5}
                marks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                height="1rem"
                style="margin:2rem;"
              />
            </h4> , {
                refs: 'manualHzModal',
                title: content.title,
                titleTag: 'h4',
                hideFooter: true,
                hideHeaderClose: true,
                okTitle: '확인',
                okVariant: 'primary',
                cancelTitle: '취소',
                centered: true,
                }).then(async ok => {
                    if(ok) return await this.setCommand(command, this.percentage2Frequecy('set', value))
                    return
                })
        },
        async setValByModal(command, val) {
          const content = this.getContentList(command, val)
          let value = val || 0
          this.$bvModal.msgBoxConfirm(<h4> 
              <b-input-group Append={content.unit} class="mt-1" >
                <b-form-input 
                  id="rewriteBox" 
                  vModel={value} 
                  vOn:keyup_enter={document.getElementById('rewriteBox').blur()} 
                  type="number" style="width: 20%;" 
                  autofocus 
                  required/> 
              </b-input-group>
            </h4> , {
                refs: `${content.command}`,
                title: `${content.title}`,
                titleTag: 'h4',
                noCloseOnBackdrop: true,
                okTitle: '수정',
                okVariant: 'warning',
                cancelTitle: '취소',
                centered: true,
                }).then(async ok => {
                    if(command.includes('Acc') || command.includes('Dec')) value = value * 10
                    if(ok) await this.setCommand(command, value)
                })
        },
        async setValByTimeModal(command, time) {
          const spell = command.substring(command.length - 1, command.length)
          const start = spell === 'S'? true: false
          let value = time[`${start? 'start': 'end'}`]
          this.$bvModal.msgBoxConfirm(<h4> 
              <b-input-group class="mt-1" >
                <b-form-input 
                  id="rewriteBox" 
                  vModel={value} 
                  vOn:keyup_enter={document.getElementById('rewriteBox').blur()} 
                  type="time" style="width: 20%;" 
                  autofocus 
                  required/> 
              </b-input-group>
            </h4> , {
                refs: ``,
                title: `운전 ${start? '시작': '정지'} 시간 설정`,
                titleTag: 'h4',
                noCloseOnBackdrop: false,
                okTitle: '수정',
                okVariant: 'warning',
                cancelTitle: '취소',
                centered: true,
                }).then(async ok => {
                  if(ok) {
                    await this.setCommand(`${command}H`, value.split(':')[0])
                    await this.setCommand(`${command}M`, value.split(':')[1])
                    return time[start?'start': 'end'] = value
                  }
                })
        },
        getContentList(command, val) {
          switch (command) {
            case `Q1ChManuFrq`: 
            case `Q2ChManuFrq`: return {command, title: '수동 운전 출력', unit: '％'}
            case `Q1ChWorkDelayTimer`: 
            case 'Q2ChWorkDelayTimer': return {command, title: '운전 시작 대기 시간', unit: '분'}
            case `Q1ChStopDelayTimer`: 
            case `Q2ChStopDelayTimer`: return {command, title: '운전 정지 대기 시간', unit: '분'}
            case `Q1ChWorkTimer`: 
            case 'Q2ChWorkTimer': return {command, title: '운전 시간', unit: '분'}
            case 'Q1ChStopTimer': 
            case 'Q2ChStopTimer': return {command, title: '정지 시간', unit: '분'}
            case 'Q1ChTargetTemp': 
            case 'Q2ChTargetTemp': return {command, title: '목표 온도', unit: '℃'}
            case 'Q1ChTargetFrq': 
            case 'Q2ChTargetFrq': return {command, title: '운전 출력(주기 모드)', unit: '％'}
            case 'Q1ChAccTime': 
            case 'Q2ChAccTime': return {command, title: 'Acc 작동 시간', unit: '％'}
            case 'Q1ChDecTime': 
            case 'Q2ChDecTime': return {command, title: 'Dec 정지 시간', unit: '％'}
            case 'Q1ChSche1Frq': 
            case 'Q1ChSche2Frq': 
            case 'Q1ChSche3Frq': 
            case 'Q1ChSche4Frq': 
            case 'Q1ChSche5Frq': 
            case 'Q2ChSche1Frq': 
            case 'Q2ChSche2Frq': 
            case 'Q2ChSche3Frq': 
            case 'Q2ChSche4Frq': 
            case 'Q2ChSche5Frq': return {command, title: '가동률', unit: '％'}
            default: return {}
          }
        }
    }
}

</script>

<style scoped>
.thStyle {
  background-color: #8F9DC3;
  border: 2px ridge;
}
.btnStyle {
  background-color: #324680;
  border: 2px ridge;
  height: 4rem;
}

.title {
  background-color: #DBDBDB; 
  border: 2px ridge;
  color: black; 
  font-weight: bold;
  margin-bottom: 3rem;
}
.tableStyle {
  width: -webkit-fill-available; 
  color: white; 
  font-size: large; 
  margin-top: 0.1rem;
}
.active {
  background-color: yellow;
  color: #FF0000;
  font-weight: bold;
  border: 2px ridge;
}
.targetVal {
  color: blue;
  padding: 0.15rem;
  border: 2px ridge;
  border-radius: 0.5rem;
  background-color: #E7E7E7;
}
.disabled {
  background-color: #a1a1a1;
}
</style>