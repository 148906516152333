<template>
    <div 
    style="
      margin-top: 0.2rem; 
      border: groove; 
      border-radius: 0.5rem; 
      background-color: #E7ECF4;">
      <div v-if="state === null" class="mt-1">
          <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
              <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
              <br><br>
              <b>잠시만 기다려주세요...</b>
          </h3>
      </div>
      <div v-if="state === false" class="mt-1">
          <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
              <h4>접속 불가</h4><hr>
              <feather-icon
                  icon="AlertTriangleIcon"
                  style="color:red;"
                  size="45"/>
              <hr>
              <b>점검 사항</b>
              <hr>
              <p>1) 장비 전원</p>
              <p>2) 인터넷 연결</p>
              <p>3) 네트워크 라인</p>
          </h3>
          <br>
          <b-button variant="info" @click="init">재연결</b-button>
          <hr>
      </div>
      <table v-if="state">
          <tr>
              <th colspan="9" class="thStyle">
              시스템 정보
              </th>
          </tr>
          <tr>
              <td class="thStyle" style="width: 50%">
              <vue-apex-charts
                  ref="charti2"
                  type="bar"
                  height="150"
                  :options="tempOptions"
                  :series="tempSeries"
              />
              </td>
              <td class="thStyle" style="width: 50%">
              <vue-apex-charts
                  ref="charti"
                  type="donut"
                  height="160"
                  :options="powerOptions"
                  :series="powerSeries"
              />
              </td>
          </tr>
      </table>
      
      <table 
      v-if="state"
      style="
          width: -webkit-fill-available; 
          background-color: #324680; 
          color: white; 
          font-size: x-large; 
          margin-top: 0.1rem;">
        <tr >
          <td style="border: outset; width: 50%"
          @click="changeState(state)">
            <feather-icon
                :icon="`${(state===true)?'Settings':'Monitor'}Icon`"
                style=""
                size="24"/>({{ (state===true)?'설정':'메인' }})
          </td>
          <td style="border: outset; width: 50%"
          @click="init()">
            <feather-icon
                icon="RefreshCwIcon"
                style=""
                size="24"/>(갱신)
          </td>
        </tr>
      </table>

      <table v-if="state === 'Setting'" style="
          width: -webkit-fill-available; 
          background-color: #8F9DC3; 
          color: white; 
          font-size: x-large; 
          margin-top: 0.1rem;">
        <tbody style="font-size: x-large;">
          <tr>
            <td colspan="6" style="color:black; border: groove;"> 목표 온도 설정</td>
          </tr>
          <tr style="color:black; border: groove;">
            <td colspan="3" >
              온도 제어
            </td>
            <td >
              <b-form-checkbox switch v-model="autoByTemp" @change="pressBtn('autoByTemp', autoByTemp)" >
              </b-form-checkbox>
            </td>
          </tr>
          <tr>
            <td colspan="6" style="background-color:#324680">
              <vue-slider
                  v-model="SetTemperature"
                  height="1rem"
                  :lazy="true"
                  :max="40"
                  :min="0"
                  :marks="[0, 10, 20, 30, 40]"
                  :tooltip="'always'"
                  :interval="1"
                  :tooltip-formatter="`${SetTemperature} ℃`"
                  :process-style="{background: '#E78989'}"
                  :tooltip-style="{background: '#E78989', borderColor: '#E78989'}"
                  :dot-style="{borderColor: '#E78989'}"
                  :dot-tooltip-inner-style="{background: '#E78989'}"
                  style="margin:2rem;"
                  @change="pressBtn('QSetTemp', SetTemperature * 100)"
                />
            </td>
          </tr>
          <tr>
            <td colspan="6" style="color:black; border: groove;"> 수동 주파수 설정</td>
          </tr>
          <tr>
            <td colspan="6" style="background-color:#324680">
              <vue-slider
                  v-model="nowFrequency"
                  height="1rem"
                  :lazy="true"
                  :max="60"
                  :min="0"
                  :marks="[0, 10, 20, 30, 40, 50, 60]"
                  :tooltip="'always'"
                  :interval="1"
                  :tooltip-formatter="`${nowFrequency} Hz`"
                  style="margin:2rem;"
                  @change="pressBtn('QinvManuFreq', nowFrequency * 166)"
                />
            </td>
          </tr>
          <tr>
            <td colspan="6" style="color:black; border: groove;"> 최저 주파수 설정</td>
          </tr>
          <tr>
            <td colspan="6" style="background-color:#324680">
              <vue-slider
                  v-model="invMinFreq"
                  height="1rem"
                  :lazy="true"
                  :max="60"
                  :min="0"
                  :marks="[0, 10, 20, 30, 40, 50, 60]"
                  :tooltip="'always'"
                  :interval="1"
                  :tooltip-formatter="`${invMinFreq} Hz`"
                  style="margin:2rem;"
                  @change="pressBtn('QinvMinFreq', invMinFreq * 166)"
                />
            </td>
          </tr>
        </tbody>
      </table>

      <table 
      v-if="state === true"
        style="
          width: -webkit-fill-available; 
          background-color: #8F9DC3; 
          color: white; 
          min-height: 12rem; 
          font-size: x-large; 
          margin-top: 0.1rem;">
          <tbody style="border-bottom: 2px groove;">
            <tr >
              <td rowspan="2" style="border-right: 2px groove">
                인버터
              </td>
              <td>
                상태
              </td>
              <td :style="`color: ${(invPower === 'RUN')? 'red': 'black'}`">
                {{ (invPower === 'RUN')? '동작중': '정지중' }}
              </td>
            </tr>
            <tr style="border-top: 2px groove">
              <td>
                주파수
              </td>
              <td>
                {{ nowFrequency }} Hz
              </td>
            </tr>
          </tbody>
          
          
        
        <tr>
          <th colspan="6" style="color:black"> 
            운전모드
          </th>
        </tr>
        <tbody style="font-size: xx-large;">
          <tr>
            <td 
            rowspan="2"
            :style="`
            min-height: 200px;
            background-color: ${(mMode === '자동')? 'yellow':'#324680'}; 
            color: ${(mMode === '자동')? 'red':'white'}; 
            border: 3px outset;`"
            @click="pressBtn('mMode', (mMode === '자동')? 0: 1)">
              {{mMode}}
            </td>
            <td colspan="2"
            :style="`
            background-color: ${(sMode === 'INV')? 'yellow':'#324680'}; 
            color: ${(sMode === 'INV')? 'red':'white'}; 
            border: 3px outset;`"
            @click="pressBtn('sMode', (sMode === 'INV')? 0: 1)">
              {{sMode}}
            </td>
          </tr>
          <tr>
            <td colspan="2"
            :style="`
            background-color: ${(inverter === 'RUN')? 'yellow':'#324680'};
            color: ${(inverter === 'RUN')? 'red':'white'};  
            border: 2px outset;`"
            @click="pressBtn('inverter', (inverter === 'RUN')? 0: 1)">
              INV {{inverter}}
            </td>
          </tr>
        </tbody>
        
        
        
      </table>
      <table
        v-if="(state === true && mMode === '수동') || state === 'Setting'"
        style="
        width: -webkit-fill-available; 
        margin-top: 0.3rem; 
        background-color: #8F9DC3; 
        color: white; 
        font-size: x-large;">
        <tr>
          <th colspan="6" style="color:black">
            Fan 그룹 제어
          </th>
        </tr>
        <tr>
          <td
          v-for="fan of fans" 
          :key="fan.name"
          style=" background-color: #8F9DC3; border: groove;">
            {{fan.name}}
          </td>
        </tr>
        <tr v-if="state !== 'Setting'">
          <td
            v-for="fan of fans" 
            :key="fan.name"
            @click="pressBtn(`${fan.name}`, (fan.val === 'RUN')? 0: 1)"
            :style="`
            background-color: ${(fan.val === 'RUN')? 'yellow':'#324680'};
            color: ${(fan.val === 'RUN')? 'red':'white'}; 
            height: 3rem; 
            border: 3px outset;`">
              {{ fan.val }}
            </td>
        </tr>
        <tr v-else>
          <td
            v-for="fan of fans" 
            :key="fan.name"
            :style="`
            background-color: '#324680'};
            color: 'white'}; 
            height: 3rem; 
            border: 3px outset;`">
              <b-form-checkbox switch v-model="fan.auto" @change="pressBtn(`${fan.name}Auto`, fan.auto)" >
              </b-form-checkbox>
          </td>
        </tr>
      </table>

    </div>
</template>

<script>
import { 
    BFormCheckbox,
    BButtonGroup,
    BProgress,
    BButton,
    BTable,
    BSpinner,
    BFormSelect,
    BBadge,
    BInputGroup,
    BFormInput,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'

export default {
    components: {
        VueSlider,
        BProgress,
        BFormCheckbox,
        BFormSelect,
        BSpinner,
        BButtonGroup,
        BButton,
        BTable,
        BBadge,
        BInputGroup,
        BFormInput,
        VueApexCharts: () => import('vue-apexcharts'),
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    created() {
      this.init()
    },
    data() {
        return {
            state: true, 
            mMode: false,
            sMode: false,
            inverter: 'STOP',
            invPower: false,
            nowFrequency: 0,
            invMinFreq: 0,
            fans: [],
            SetTemperature: 0,
            autoByTemp: false,
            tempSeries: [
              {
                name: '온도',
                data: [0]
              },
            ],
            powerSeries: [0, 100],
            tempOptions: {
              tooltip: {
                enabled : false
              },
              legend: {
                show: false
              },
              title: {
                text: '현재 온도 모니터'
              },
              colors: ['#FF5454'],
              xaxis: {
                labels: {
                  show: false,
                },
                categories: ['현재 온도'],
              },
              chart: {
                type: 'bar',
                stacked: false
              },
              dataLabels: {
                
                style: {
                  fontSize: '1.5rem',
                  colors: ['#DC143C'],
                },
                formatter: v => v + ' ℃'
              },
              plotOptions: {
                bar: {
                  borderRadius: 5,
                  horizontal: false,
                  columnWidth: '15%',
                  endingShape: 'rounded',
                  distributed: false,
                  dataLabels: {
                    position: 'mid'
                  }
                },
              },
              stroke: {
                show: false,
                width: 2,
                curve: 'smooth',
              },
              yaxis: {
                labels: {
                  show: true,
                },
                opposite: false,
                max: 40,
                min: 0,
                tickAmount: 10,
              },
              grid: {
                show: true
              }
            },
          
          powerOptions: {
            tooltip: {
              enabled : false
            },
            title: {
              text: '주파수 모니터'
            },
            noData: {
              text: 'No Data'
            },
            legend: {
              show: false
            },
            plotOptions: {
              pie: {
                size: '65%',
                customScale: 1,
                expandOnClick: false,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: false,
                    },
                    value: {
                      fontSize: '2rem',
                      color: 'green'
                    },
                    total: {
                      show: true,
                      showAlways: true,
                      formatter: v => v.globals.seriesTotals[0] + ' Hz'
                    }
                  }
                },
              }
            },
            colors: ['#48B100', '#D5D5D5'],
            dataLabels: {
              enabled: false,
              formatter: v => v + " Hz"
            },
            labels: [
              '현재 출력'
            ],
          },
          
        }
    },
    methods: {
        async init(renew) {
            this.state = renew || null
            const res = await store.dispatch('button/getState', { id: this.buttonItem._id })
            if(res.data.status === 'success') {
              const inverter = res.data.data
              this.mMode = inverter.mMode
              this.sMode = inverter.sMode
              this.nowFrequency = inverter.invNowFreq
              this.invMinFreq = inverter.invMinFreq
              // let percentage = (this.nowFrequency / 6) * 10
              this.invPower = inverter.invPower
              this.inverter = inverter.inverter
              this.SetTemperature = inverter.SetTemp
              this.autoByTemp = inverter.autoByTemp
              this.fans = inverter.fans

              this.state = true
              return setTimeout(() => {
                this.$refs['charti'].updateSeries([this.nowFrequency, 60 - this.nowFrequency]);
                this.$refs['charti2'].updateSeries([{name: '온도', data: [inverter.nowTemp]}]);
              }, 200);
            } else return this.state = false
        },
        async pressBtn (command, val) {
          const result = await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: {
              command: `set${command}`,
              commandValue: val
          }})
          if(result.data.data.Result) {
            const response = result.data.data.Data
            const cmd = response.command
            const value = response.val
            if(result.data.data.Result && cmd.includes('fan')) {
              let targetFan = this.fans.find(el => el.name === cmd)
              return targetFan.val = value
            }
            if(cmd === 'mMode') return this[cmd] = value, setTimeout(this.init, 150);
            if(cmd === 'invManuFreq') return this.$refs['charti'].updateSeries([this.nowFrequency, 60 - this.nowFrequency]);
            if(cmd === 'invMinFreq') return
            return this[cmd] = value
          }
          
        },
        changeState (state) {
          return this.state = (state === true)? 'Setting': true
        }
    },
}

</script>
<style scoped>
</style>