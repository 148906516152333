<template>
    <div style="margin-top: 0.2rem; border: groove; border-radius: 0.5rem; background-color: #E7ECF4;">
        <div v-if="state === null" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
                <br><br>
                <b>잠시만 기다려주세요...</b>
            </h3>
        </div>
        <div v-if="state === false" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <h4>접속 불가</h4><hr>
                <feather-icon
                    icon="AlertTriangleIcon"
                    style="color:red;"
                    size="45"/>
                <hr>
                <b>점검 사항</b>
                <hr>
                <p>1) 장비 전원</p>
                <p>2) 인터넷 연결</p>
                <p>3) 네트워크 라인</p>
            </h3>
            <br>
            <b-button variant="info" @click="init">재연결</b-button>
            <hr>
        </div>
        <table v-if="state" style="width: -webkit-fill-available;">
            <tr >
                <td style="width: 50%">
                    <vue-apex-charts
                    ref="charti"
                    type="donut"
                    height="150"
                    :options="powerOptions"
                    :series="powerSeries"
                    />
                </td>
                <td style="width: 50%; ">
                    <tr>
                        <td>
                            <p class="title"> 현재 주파수 : </p>
                        </td>
                        <td>
                            <p class="val">{{hz}} hz</p>
                        </td>
                    </tr>
<!--                     <tr>
                        <td>
                            <p class="title"> 현재 출력률 : </p>
                        </td>
                        <td>
                            <p class="val">{{nowPercentage}} %</p>
                        </td>
                    </tr> -->
                    <tr>
                        <td>
                            <p class="title"> 대기 출력률 : </p>
                        </td>
                        <td>
                            <p class="title">{{100 - nowPercentage}} %</p>
                        </td>
                    </tr>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <b-button 
                        style="width: 90%;"
                        variant="info" 
                        @click="init()">
                        데이터 새로고침
                    </b-button>
                </td>
            </tr>
            <br>
            <tr >
                <td colspan="2" >
                    <h4>출력 조절</h4>
                    <vue-slider
                        v-model="targetPerrcentage"
                        height="1.3rem"
                        :lazy="true"
                        :max="100"
                        :min="0"
                        :marks="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
                        :tooltip="'always'"
                        :interval="5"
                        :tooltip-formatter="`${targetPerrcentage}%`"
                        style="margin:2rem;"
                        @change="onSlide(targetPerrcentage)"
                      />
                </td>
            </tr>
            <br>
            <tr>
                <td colspan="2">
                    <b-button 
                        style="width: 90%;"
                        variant="danger" 
                        @click="stop()">
                        정지
                    </b-button>
                </td>
            </tr>
            <br>
        </table>
        
        
    </div>
</template>

<script>
import { 
    BButtonGroup,
    BProgress,
    BButton,
    BTable,
    BSpinner,
    BFormSelect,
    BBadge,
    BInputGroup,
    BFormInput,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'

export default {
    components: {
        VueSlider,
        BProgress,
        BFormSelect,
        BSpinner,
        BButtonGroup,
        BButton,
        BTable,
        BBadge,
        BInputGroup,
        BFormInput,
        VueApexCharts: () => import('vue-apexcharts'),
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            powerOptions: {
                tooltip: {
                    enabled : false
                },
                title: {
                    text: '현재 출력'
                },
                noData: {
                    text: 'No Data'
                },
                legend: {
                    show: false
                },
                plotOptions: {
                pie: {
                    size: '65%',
                    customScale: 1,
                    expandOnClick: false,
                    donut: {
                        labels: {
                            show: true,
                            name: {
                            show: false,
                            },
                            value: {
                            fontSize: '2rem',
                            color: 'blue'
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                formatter: v => {
                                    return `${v.globals.seriesTotals[0]}%`
                                }
                            }
                        }
                    },
                }
                },
                colors: ['#4354FF', '#D5D5D5'],
                dataLabels: {
                    enabled: false,
                    formatter: v => v + " hz"
                },
                labels: [
                    '현재 출력'
                ],
            },
            powerSeries: [0, 100],
            state: null,
            hz: 0,
            nowPercentage: 0,
            targetPerrcentage: 0
        }
    },
    methods: {
        async init() {
            const res = await store.dispatch('button/getState', { id: this.buttonItem._id })
            if(res.data.status === 'success') {
                
                this.hz = res.data.data
                this.nowPercentage = (this.hz / 6) * 10
                this.targetPerrcentage = this.nowPercentage
                console.log('this.nowPercentage', this.nowPercentage)
                this.powerSeries = [this.nowPercentage, 100 - this.nowPercentage]
                return this.state = true
                //return setTimeout(() => this.$refs['charti'].updateSeries([this.hz, 60 - this.hz]), 350);
            }
            return this.state = false
            
        },
        async onSlide (tHz) {
            this.hz = (tHz * 6) / 10
            this.nowPercentage = tHz
            const res = await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: {
                command: (this.nowPercentage != 0)? 'work': 'stop',
                commandValue: this.hz,
            } })
            
            return this.powerSeries = [this.nowPercentage, 100 - this.nowPercentage]
        },
        async stop() {
            await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: {
                command: 'stop',
                commandValue: 0,
            } })
            this.hz = 0
            this.nowPercentage = 0
            this.targetPerrcentage = 0
            return this.powerSeries = [this.nowPercentage, 100 - this.nowPercentage]
        },
    },
}

</script>
<style scoped>
p.title {
    font-size: larger;
    color:black;
    text-align: center;
    font-weight: bold;
}
p.val {
    font-size: larger;
    color:green;
    text-align: center;
    font-weight: bold;
}
</style>