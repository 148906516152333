<template>
  <div>
    <b-button
      variant="outline-primary"
      class="active"
      size="sm"
      block
      @click="toggleSettingList"
    >
      <b>자동화 목록 {{(temp)? '숨기기': '펼치기'}}</b> 
    </b-button>

    <!-- collapse -->
    <b-collapse
      ref="autoSettings"
      class="mt-0"
      :visible="false"
    >
      <!-- <draggable
        v-model="settingList"
        class="list-group"
        tag="ul"
      > -->
       <!--  disabled="true" -->
        <!-- :disabled="!isEditing" -->
<!--         <transition-group
          type="transition"
          name="flip-list"
          :disabled="(isEditing)? 'true': 'false'"
        > -->
          <b-list-group-item
            v-for="listItem in settingList"
            :key="listItem._id"
            tag="li"
            :variant="listItem.isOn ? '' : 'secondary'"
            :class="listItem.isOn ? '' : 'text-secondary'"
            :style="`background-color: ${((listItem.totalMin > nowMin) && listItem.isOn)?'#E6E8E6':''}; border: ${((listItem.totalMin > nowMin) && listItem.isOn)?'outset':'inset'}`"
          >
            <div class="d-flex justify-content-between align-items-center" style="font-size:larger">
              <feather-icon
                :icon="`${getMode(listItem.mode) === '시간' ? 'ClockIcon': 'ThermometerIcon'}`"
                size="40"
              />

              <!-- <b-badge
                :variant="modeColor"
                class="badge-round text-center"
              >
               
                <span style="color:blue">{{ getMode(listItem.mode) }}</span><br><span style="">제어</span>
              </b-badge> -->
              <b-card-text
                v-if="listItem.mode === 'time'"
                class="font-weight-bold mb-0"
                style="margin: 0 auto"
              >
                <b-badge
                  v-for="item in listItem.days"
                  :key="item"
                  class="badge-round text-center mr-auto"
                  pill
                  style="background-color:linen"
                >
                  <!-- variant="light-warning" -->
                <span style="color:#B92EFF">{{ getDays(item) }}</span>
                </b-badge>
                <hr style="border: 1px inset black; margin: 0.3rem">
                <!-- parseInt(startTime.split(':')[0])>=12)? '오후': '오전' -->
                <strong :style="`font-weight: bold; color:${(parseInt(listItem.startTime.split(':')[0]) >= 12)? 'blue': 'orange'}`"> 
                  {{ parseInt(listItem.startTime.split(':')[0]) >= 12? '오후': '오전' }} 
                </strong>
                <strong style="color:black"> 
                  {{ parseInt(listItem.startTime.split(':')[0]) >= 12 ? `${parseInt(listItem.startTime.split(':')[0])-12}시 ${parseInt(listItem.startTime.split(':')[1])}분` : `${parseInt(listItem.startTime.split(':')[0])}시 ${parseInt(listItem.startTime.split(':')[1])}분` }} 
                </strong>
                에 <span :style="`font-weight: bold; color: ${(listItem.command === 'work' || listItem.command === 'open') ? 'green': (listItem.command === 'stop')?'red': 'orange'}`"> [ {{ getCommand(listItem.command, listItem.commandValue) }} ]</span>
                <br>
                <!-- <strong> {{ getTime(listItem.endTime) }} </strong> 까지 -->
              </b-card-text>
              <b-card-text
                v-if="listItem.mode === 'periodic'"
                class="font-weight-bold mb-0"
              >
                <strong> {{ getPeriodic(listItem.periodic) }} </strong>마다
              </b-card-text>
              <b-card-text
                v-if="listItem.mode === 'sensor' && listItem.sensorId.type !== 'rain'"
                class="font-weight-bold mb-0"
              >
                <b-badge
                  v-for="item in listItem.days"
                  :key="item"
                  variant="light-success"
                  class="badge-round text-center mr-auto"
                  pill
                >
                  {{ getDays(item) }}
                </b-badge><hr>
                <!-- <strong> {{ listItem.sensorId !== null ? listItem.sensorId.name : '' }} </strong>가 <br> -->
                <strong 
                v-if="type==='inverter' || type==='inverter_hd'"
                style="color:steelblue">최소 {{ listItem.minValue.split('/')[0] }}℃</strong>

                <strong
                v-else
                style="color:tomato">{{ listItem.maxValue.split('/')[0] }}℃ 이상 :: 동작</strong>

                <strong v-if="type==='inverter' || type==='inverter_hd'"> / 출력 {{ listItem.minValue.split('/')[1]/60*100 }}% </strong><br>

                <strong 
                v-if="type==='inverter' || type==='inverter_hd'"
                style="color:tomato">최대 {{ listItem.maxValue.split('/')[0] }}℃</strong>

                <strong
                v-else
                style="color:steelblue">{{ listItem.minValue.split('/')[0] }}℃ 이하 :: 정지</strong>

                <strong v-if="type==='inverter' || type==='inverter_hd'"> /  출력{{ listItem.maxValue.split('/')[1]/60*100 }}% </strong>

              </b-card-text>

              <b-card-text
                v-if="listItem.mode === 'sensor' && listItem.sensorId.type === 'rain'"
                class="font-weight-bold mb-0"
              >
                <strong style="color:blue"> 비가 오면 {{deviceName}}을 </strong>
              </b-card-text>
              
              <!-- <b-badge
                v-if="listItem.mode !== 'sensor'"
                :variant="`light-${(listItem.command === 'work' || listItem.command === 'open')? 'info': 'warning'}`"
                class="badge-round text-center"
              >
                {{ getCommand(listItem.command, listItem.commandValue) }}
              </b-badge>
              <b-badge
                v-else
                variant="light-info"
                class="badge-round text-center"
              >
              <strong v-if="type==='inverter' || type==='inverter_hd'" style="color: blue">
                출력<br>{{ (listItem.sensorId !== null && sliderValue) ? Math.round(sliderValue/60*100)+'%' : '0%' }}
              </strong>
              <strong v-else>
                온도
              </strong>
              </b-badge> -->
              <b-badge
                v-if="listItem.mode !== 'sensor'"
                :variant="`${(listItem.isOn)? (listItem.totalMin > nowMin)?'info': 'warning': 'secondary'}`"
                class="badge-round text-center"
              >
                {{ (listItem.isOn)? ((listItem.totalMin > nowMin))? fromNow(listItem.startTime):'완료' : '비활성'}}
              </b-badge>
              <!-- <b-badge
                v-else
                :variant="`light-info`"
                class="badge-round text-center"
              >
                <b style="font-size: 1.5rem">
                  {{ Math.round(sliderValue/60*100)+'％'}} <br>{{(fanWork === 'work')? '동작중': '정지중'}}
                </b>
              </b-badge> -->
            </div>

            
            <hr v-if="isEditing === true">
            <div
              v-if="isEditing === true"
              class="d-flex justify-content-between align-items-center"
            >
              <b-button
                variant="outline-danger"
                size="sm"
                block
                @click="remove(listItem._id)"
              >
                삭제
              </b-button>
              <b-button
                variant="outline-warning"
                class="ml-1 my-1"
                size="sm"
                block
                @click="edit(listItem._id)"
              >
                편집
              </b-button>

              <b-form-checkbox
                v-model="listItem.isOn"
                class="custom-control-primary ml-1"
                switch
              />
            </div>

          </b-list-group-item>
<!--         </transition-group> -->
<!--       </draggable> -->

      <b-button
        v-if="settingList.length > 0"
        :variant="toggleEditColor"
        size="sm"
        block
        class="mt-1"
        @click="toggleEdit"
      >
        {{ toggleEditText }}
      </b-button>
    </b-collapse>
  </div>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import {
  BCardText, BCollapse, BButton, BListGroupItem, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

export default {
  components: {
    BCardText,
    BCollapse,
    BButton,
    BListGroupItem,
    BBadge,
    BFormCheckbox,
    draggable,
  },
  props: {
    buttonId: {
      type: String,
      required: true,
    },
    slider: {
      type: Number,
      //required: true,
    },
    fanWork: {
      type: String,
    }
  },
  watch: {
    slider() {
      this.sliderValue = (this.slider) ? this.slider : this.sliderValue
    }
  },
  data() {
    return {
      settingList: [],
      modeColor: 'light-info',
      isEditing: false,
      toggleEditText: '자동화 동작 편집',
      toggleEditColor: 'outline-warning',
      deviceName: '',
      sliderValue: 0,
      type: '',
      nowMin: '',
      temp: false
    }
  },
  created() {
    this.initSettings()
    this.getNow()
  },
  mounted() {
    this.temp = this.$refs.autoSettings.show
  },
  methods: {
    fromNow(date) {
      moment.locale('ko')
      let front = moment().format('YYYY-MM-DD ')
      let back = date
      return moment(front + back).fromNow()
    },
    async sortDays(days) {
      return days
    },
    async getNow() {
      
      let nowHour = moment().hour()
      let nowMinute = moment().minute()
      this.nowMin = (nowHour * 60) + nowMinute
    },
    async initSettings() {
      await store.dispatch('button/fetchSettings', { id: this.buttonId }).then(result => {
        this.settingList = result.data.autoSettings
        for(let autoSet of this.settingList) {
          if(autoSet.mode === 'time') autoSet.totalMin = parseInt(autoSet.startTime.split(':')[0]) * 60 + parseInt(autoSet.startTime.split(':')[1])
          
        }
        this.settingList.sort((a, b) => a.totalMin - b.totalMin)
      })
      await store.dispatch('button/fetchButton', { id: this.buttonId }).then(result => {
         this.deviceName = result.data.name
         this.sliderValue = (this.slider) ? this.slider : result.data.commandValue
         this.type = result.data.type
      })
      //console.log(this.settingList)
      if (this.settingList.length === 0) {
        store.dispatch('button/updateButton',
        {
          id: this.buttonId,
          queryBody: {
            isAuto: false,
          },
        })
        .then(() => {
          let length = this.settingList.length
          this.$emit('isAutoFalse', length)
        })
        .catch(err => {
          console.log(err)
        })
      }
      //console.log(this.settingList)
      /* let apm = (parseInt(this.settingList[0].startTime.split(':')[0])>=12)? '오후': '오전'
      console.log(apm) */
    },

    getDays(day) {
      if (day === 0) return '월'
      if (day === 1) return '화'
      if (day === 2) return '수'
      if (day === 3) return '목'
      if (day === 4) return '금'
      if (day === 5) return '토'
      if (day === 6) return '일'
      return ''
    },

    getMode(mode) {
      if (mode === 'time') {
        return '시간'
      } if (mode === 'periodic') {
        return '주기'
      } if (mode === 'sensor') {
        return '센서'
      }
      return ''
    },

    getTime(time) {
      const splited = time.split(':')
      console.log(`${splited[0]}시 ${splited[1]}분`)
      return `${splited[0]}시 ${splited[1]}분`
    },

    getPeriodic(time) {
      if (time / 1440 > 1) {
        return `${time / 1440}일`
      }
      if (time / 60 > 1) {
        return `${time / 60}시간`
      }
      return `${time}분`
    },

    getCommand(command, commandValue) {
      commandValue = (commandValue) ? commandValue : 0.1
      if (command === 'open') {
        return '열기'
      }
      if (command === 'stop') {
        return '정지'
      }
      if (command === 'close') {
        return '닫기'
      }
      if (command === 'work') {
        return `${(this.type === 'inverter' || this.type === 'inverter_hd')? Math.round(commandValue/60*100) + '% 동작': '동작' }`
      }
      return ''
    },

    toggleSettingList() {
      this.$refs.autoSettings.show = !this.$refs.autoSettings.show
      this.temp = this.$refs.autoSettings.show 
    },

    toggleEdit() {
      this.isEditing = !this.isEditing

      if (this.isEditing) {
        this.toggleEditColor = 'outline-warning'
        this.toggleEditText = '수정 완료'
      } else {
        this.toggleEditColor = 'outline-dark'
        this.toggleEditText = '자동화 동작 편집'
        store.dispatch('button/setSettings', { id: this.buttonId, queryBody: this.settingList })
      }
    },

    remove(id) {
      this.$bvModal
        .msgBoxConfirm('자동화 설정을 정말 삭제하시겠습니까?', {
          title: '자동화 설정 삭제',
          size: 'sm',
          okVariant: 'danger',
          okTitle: '삭제',
          cancelTitle: '취소',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store.dispatch('button/deleteSetting', { buttonId: this.buttonId, settingId: id })
              .then(() => {
                this.initSettings()
              }).catch(error => {
                console.log(error)
              })
          }
        })
    },

    edit(id) {
      this.$router.push({ name: 'remote-setting', params: {buttonId: this.buttonId, autoId: id} })
    },

  },
}
</script>

<style>
.list-group-item {
  transition: all 0.5s
}
</style>
