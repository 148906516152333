<template>
    <div>
        <div v-if="state === null" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
                <br><br>
                <b>잠시만 기다려주세요...</b>
            </h3>
        </div>
        <div v-if="state === false || (state === 'err' && errMsg === false)" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <h4>접속 불가</h4><hr>
                <feather-icon
                    icon="AlertTriangleIcon"
                    style="color:red;"
                    size="45"/>
                <hr>
                <b>점검 사항</b>
                <hr>
                <p>1) 장비 전원</p>
                <p>2) 인터넷 연결</p>
                <p>3) 네트워크 라인</p>
            </h3>
            <br>
            <b-button variant="info" @click="init">재연결</b-button>
            <hr>
        </div>
        <div v-if="state === 'err' && errMsg" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <feather-icon
                icon="AlertTriangleIcon"
                style="color:red;"
                size="30"/>
                <br><br>
                <h2>냉각기 오류 알림</h2>
                <hr>
                <h4>내용 : "{{ errMsg }}"</h4> 
            </h3>
            <br>
        </div>
        <div
        v-if="state === true" 
        :style="`
              float:right;  
              width:-webkit-fill-available; 
              padding: 0.5rem;
              border:inset;
              background-color:#2230B3;
              border-radius: 0.1rem;`">
                <h2 
                  style="color:white; margin-top: 0.5rem;">
                    DIGITAL MILK COOLER 
                </h2>
                <div style="margin: 0 auto;
                      width:80%;
                      border: 2px inset grey;
                      border-radius: 0.5rem;
                      background-color: LightBlue;
                      padding: 1rem;
                      color: black
                      font-size: x-large;">
                    <div style="font-weight: bold;">
                        <th> [ {{mode}} ] </th>
                        <div v-show="mode === '정지'">
                            
                            <h1 href="http://www.samhoct.com/" target="_blank">
                                삼 호 C T
                            </h1> 
                            <br><br>
                            ☏: <a href="tel:062-955-5625">062-955-5625</a>
                            
                        </div>
                        <div v-if="mode === '냉각 모드'" style="text-align: left;">
                            <table>
                                <tr>
                                    <td>
                                        {{ display }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        현재 온도 > {{ temperature }} ℃
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        설정 온도 > {{ settingTemperature }} ℃
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div v-if="mode === '세척 모드'" style="text-align: left;">
                            <table>
                                <tr>
                                    <td>
                                        {{ display }} > {{ step }} - {{ cleanState }} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        현재 온도 > {{ temperature }} ℃
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <b-button 
                        style="width: 20%; margin: 0.5rem"
                        variant="info"
                        @click="pressBtn('Cooler')"
                        v-show="mode === '정지'">
                        냉각
                    </b-button>
                    <b-button 
                        style="width: 20%; margin: 0.5rem"
                        variant="success"
                        @click="pressBtn('Start')"
                        v-show="mode !== '정지'">
                        시작
                    </b-button>
                    <b-button 
                        style="width: 20%; margin: 0.5rem"
                        variant="danger"
                        @click="pressBtn('Stop')"
                        v-show="mode !== '정지'">
                        정지
                    </b-button>
                    <b-button 
                        style="width: 20%; margin: 0.5rem"
                        variant="primary"
                        @click="pressBtn('Clean')"
                        v-show="mode === '정지'">
                        세척
                    </b-button>
                    <b-button 
                        style="width: 20%; margin: 0.5rem"
                        variant="outline-warning"
                        @click="init()">
                        <feather-icon
                            icon="RefreshCwIcon"
                            style=""
                            size="14"/>
                    </b-button>
                </div>
            </div>
    </div>
</template>

<script>
import { 
    BButtonGroup,
    BButton,
    BTable,
    BSpinner,
} from 'bootstrap-vue'
import store from '@/store'

export default {
    components: {
        BSpinner,
        BButtonGroup,
        BButton,
        BTable
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    created() {
        this.init()
    },
    data() {
        return {
            state: null,
            mode: null,
            display: null,
            temperature: 0,
            settingTemperature: null,
            cleanState: null,
            step: null
        }
    },
    methods: {
        async init() {
            this.state = null
            const res = await store.dispatch('button/getState', { id: this.buttonItem._id })
            if(res.data.data.connect === 'err') return this.state = 'err', this.errMsg = res.data.data.errMsg
            if(res.data.status === 'success') {
                this.state = true
                this.mode = res.data.data.mode
                if(this.mode === '냉각 모드') {
                    this.display = res.data.data.display
                    this.temperature = res.data.data.temperature
                    this.settingTemperature = res.data.data.modeInfo.settingTemperature || null
                } 
                if(this.mode === '세척 모드') {
                    this.display = res.data.data.display
                    this.temperature = res.data.data.temperature
                    this.cleanState = res.data.data.modeInfo.state || null
                    this.step = res.data.data.modeInfo.step || null
                }
            }
        },
        async pressBtn (mode) {
            this.state = null
            const param = {command: `set${mode}Mode`,}
            await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
            return setTimeout(() => this.init(), 0);
        }
    },
}

</script>