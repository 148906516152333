<template>
    <div>
        <div v-if="state === null" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
                <br><br>
                <b>잠시만 기다려주세요...</b>
            </h3>
        </div>
        <div v-if="state === false" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <h4>접속 불가</h4><hr>
                <feather-icon
                    icon="AlertTriangleIcon"
                    style="color:red;"
                    size="45"/>
                <hr>
                <b>점검 사항</b>
                <hr>
                <p>1) 장비 전원</p>
                <p>2) 인터넷 연결</p>
                <p>3) 네트워크 라인</p>
            </h3>
            <br>
            <b-button variant="info" @click="init">재연결</b-button>
            <hr>
        </div>
        <div v-if="state === 'err'">
            <div class="mt-1">
                <feather-icon
                icon="AlertTriangleIcon"
                style="color:orange;"
                size="30"/>
                <br><br>
                <h2 style="font-weight: bold;">에러 발생</h2><hr>
                <h4 v-for="(msg, idx) in errMsg" :key="idx"> 
                    {{idx + 1}}. {{ msg }}
                </h4>
                <hr>
                <b-button @click="init" variant="outline-secondary">재연결</b-button>
                <hr>
                <b-button @click="setVal('ErrReset', 1), init()" variant="outline-danger">에러 초기화</b-button>
            </div>    
        </div>
        <div v-if="state === true" >
            <table class="tableStyle">
                <tr >
                    <td @click="changePage(mainPage)" colspan="6">
                        <div style="border: outset; border-radius: 0.5rem; background-color: #BFBFBF; margin: 5px;">
                            <feather-icon
                                :icon="`${(mainPage)?'Settings':'Monitor'}Icon`"
                                size="24"/>({{(mainPage)?'설정 화면':'메인 화면' }})
                        </div>
                    </td>
                    <td colspan="6" @click="init()">
                        <div style="border: outset; border-radius: 0.5rem; background-color: #BFBFBF; margin: 5px;">
                            <feather-icon
                                icon="RefreshCwIcon"
                                size="24"/>(새로 고침)
                        </div>
                    </td>
                </tr>
                <tbody v-show="mainPage">
                    <tr v-for="device in deviceList" :key="device.code">
                        <td colspan="12" v-show="device.able">
                            <b-card
                                style="
                                    background-color: white; 
                                    border: 2px ridge; 
                                    font-size: x-large; 
                                    margin-top: 0.1rem;
                                    font-weight: bold;
                                    margin: 0.5rem;
                                ">
                                
                                <table
                                style="width: -webkit-fill-available; border-collapse: separate; border-spacing: 0.3rem">
                                    <tr>
                                        <td 
                                        colspan="2">
                                            {{ device.name }}
                                        </td>
                                        <td colspan="1" style="width: 25%;" >
                                            <b-button 
                                            
                                            @click="activeBtn(device, 'auto', true)"
                                            :variant="(device.auto)?'success':'secondary'" 
                                            :style="`width: 100%; border: ${(device.auto)?'inset':'outset'};`" 
                                            :pressed="device.auto" >
                                                자동
                                            </b-button>
                                        </td>
                                        <td colspan="1" style="width: 25%">
                                            <b-button 
                                            @click="activeBtn(device, 'auto', false)"
                                            :variant="(!device.auto)?'danger':'secondary'"
                                            :style="`width: 100%; border: ${(!device.auto)?'inset':'outset'};`" 
                                            :pressed="!device.auto">
                                                수동
                                            </b-button>
                                        </td>
                                    </tr>
                                    <tr style="margin-top: 0.5rem;">
                                        <td colspan="2" style="width: 50%;" >
                                            <b-button 
                                            @click="activeBtn(device, 'active', true)"
                                            :variant="(device.active)?'success':'secondary'" 
                                            size="lg" 
                                            squared 
                                            :disabled="(device.auto)? true: false"
                                            :style="`width: 100%; border: ${(device.active)?'inset':'outset'};`" 
                                            :pressed="device.active">
                                                ON
                                            </b-button>
                                        </td>
                                        <td colspan="2" style="width: 50%">
                                            <b-button 
                                            @click="activeBtn(device, 'active', false)"
                                            :variant="(!device.active)?'danger':'secondary'" 
                                            size="lg" 
                                            squared 
                                            :disabled="(device.auto)? true: false"
                                            :style="`width: 100%; border: ${(!device.active)?'inset':'outset'};`" 
                                            :pressed="!device.active">
                                                OFF
                                            </b-button>
                                            
                                        </td>
                                    </tr>
                                </table>
                            </b-card>
                        </td>
                    </tr>
                </tbody>

                <tbody v-show="!mainPage">
                    <hr style="margin-top: 0; margin-bottom: 0.3rem">
                    <tr>
                        <td colspan="6">
                            <b-badge 
                            
                            @click="changeSubPage('auto', true)"
                                :variant="(subPage.auto)?'info':'light'" 
                                :style="`width: 93%; border: ${(subPage.auto)?'inset':'outset'}; font-size: large; color: black`">
                                    자동
                            </b-badge>
                        </td>
                        <td colspan="6">
                            <b-badge 
                            @click="changeSubPage('auto', false)"
                                :variant="(!subPage.auto && !logPage.val)?'info':'light'" 
                                :style="`width: 93%; border: ${(!subPage.auto && !logPage.val)?'inset':'outset'};  font-size: large; color: black`">
                                    관리
                            </b-badge>
                        </td>
                        <!-- <td colspan="2">
                            <b-badge 
                            @click="changeLogPage(true)"
                                :variant="(logPage.val)?'info':'light'" 
                                :style="`width: 93%; border: ${(logPage.val)?'inset':'outset'};  font-size: large; color: black`">
                                    기록
                            </b-badge>
                        </td> -->
                    </tr>
                    <hr style="margin-top: 0; margin-bottom: 0.3rem">
                    <tr v-if="deviceList.length > 0">
                        <td 
                        colspan="3" 
                        v-for="device in deviceList" :key="`Sett_${device.code}`">
                            <b-badge
                            @click="changeSubPage('code', device.code)"
                            :style="`border: ${(subPage.code === device.code)?'inset':'outset'}; font-size: large; color: black`"
                            :variant="(subPage.code === device.code)?'info':'light'" 
                            >
                                {{ device.name }}
                            </b-badge>
                        </td>
                    </tr>
                    <hr style="margin-top: 0; margin-bottom: 0.3rem">
                    
                    <!-- 자동 설정 페이지 -->

                    
                </tbody>
            </table>
            <table v-if="!mainPage && subPage.auto && !logPage.val" style="width: -webkit-fill-available;" class="tableStyle">
                <tr>
                    <div
                        style="width: -webkit-fill-available; display: flex">
                        <table         
                        style="width: 20%"
                        v-for="setting in settingDeviceList[0].settings" 
                        :key="`set_${setting.no}`"
                        >
                            <tr>
                                <td 
                                @click="setVal(`D${subPage.code}AutoN${setting.no}`, setting.active? 0: 1)"
                                :style="`${(setting.no < 5)?'border-right: 0.1px dotted':''};`"
                                >
                                    <b :style="`color: ${(setting.active)?'blue':'grey'}`">
                                        NO_{{ setting.no }}
                                    </b> 
                                    <b-badge
                                        :style="`border: 2px ${(setting.active)?'inset':'outset'}; `"
                                        :variant="`${(setting.active)?'success':'secondary'}`">
                                        <b>
                                            {{(setting.active)? '사용': '사용'}}
                                        </b>
                                    </b-badge>
                                </td>
                            </tr>
                            <hr style="margin-top: 0; margin-bottom: 0.3rem; border: 2px outset">
                            <tr 
                            @click="showTimeModal({
                                code: subPage.code,
                                no: setting.no
                            })" 
                            :style="`color: ${(setting.active)?'blue':''}; ${(setting.no < 5)?'border-right: 0.1px dotted':''};font-size: larger`">
                                <td >
                                    <b style="border: 2px groove; background-color: white; border-radius: 0.3rem;"> 
                                        {{ setting.time }} 
                                    </b>
                                </td>
                            </tr>
                            <hr style="margin-top: 0; margin-bottom: 1rem; border: 2px outset">
                            <tr 
                            v-for="(day, idx) in setting.days" 
                            :key="`${setting.no}_${day.text}`">
                                <h1 :style="`${(setting.no < 5)?'border-right: 1px outset':''}; `">
                                    <b-badge
                                    @click="setVal(`D${subPage.code}N${setting.no}Y${idx}`, day.val? 0: 1)"
                                        :style="`border: 2px ${(day.val === true)?'inset':'outset'};`"
                                        :variant="`${(!day.val)? 'secondary': 'success' }`"
                                        >
                                        <span>
                                            {{ day.text[0] }} <br>
                                            {{ day.text[1] }}
                                        </span>
                                    </b-badge>
                                </h1>
                                <hr style="margin-top: 0; margin-bottom: 0.3rem">
                            </tr>
                            <b-modal
                                hide-header
                                no-close-on-backdrop
                                centered
                                hide-clear-button
                                cancel-title="입력 취소"                     
                                ok-title="입력 완료"
                                @ok="setTime(subPage.code, setting.no, setting.time)"
                                :ref="`timePickerModal_${subPage.code}_${setting.no}`">
                                <h4>No_{{setting.no}} 자동 설정 시간</h4> <hr>
                                <vue-timepicker 
                                    v-model="setting.time"
                                    no-close-on-backdrop
                                    autocomplete="on"
                                    apm-label="오전/오후" 
                                    hour-label="시" 
                                    minute-label="분" 
                                    pm-text="오후" 
                                    am-text="오전" 
                                    format="HH:mm" 
                                    input-width="100%"
                                    placeholder="시간을 선택해주세요.">
                                </vue-timepicker>
                            </b-modal>
                        </table>
                    </div>
                </tr>
            </table>
        </div>
        <table v-if="!mainPage && !subPage.auto && state && !logPage.val" class="tableStyle">
            <tr v-for="set of this.settingDeviceList" :key="set.text"
            style="border: 2px ridge; font-size: x-large">
                <td>{{ set.text }}</td>
                <td>
                    <b-form-checkbox 
                        switch 
                        v-model="set.val" 
                        @change="set.text === '기록'? getFeedVal(subPage.code): setVal(set.cmd, (set.val == 1)? 0: 1)">
                    </b-form-checkbox>
                </td>
            </tr>
        </table>
        <div v-if="!mainPage && !subPage.auto && subPage.code !== 0 && state && !logPage.val" class="tableStyle">
            <b>{{deviceList[subPage.code].name}} 급이량</b>
            <b-row cols="4">
                <b-col
                    @click="setFeedVal(`QD${subPage.code}AmN${idx + 1}`,  feed.value)"
                    v-for="(feed, idx) in feedList" 
                    :key="`${idx}`">
                    <h4>#{{ feed.text }}</h4>
                    <b 
                        style="
                            background-color: white; 
                            border: 2px inset;
                            border-radius:0.2rem; 
                            font-size: large; 
                            font-weight:bold">
                                {{ feed.value }} Kg
                    </b>
                    <hr>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { 
    BFormCheckbox,
    BButtonGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BCard,
    BBadge,
    BRow,
    BCol,
} from 'bootstrap-vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import store from '@/store'
export default {
    components: {
        BFormCheckbox,
        BButtonGroup,
        BInputGroup,
        BFormInput,
        BButton,
        BTable,
        BSpinner,
        BCard,
        BBadge,
        BRow,
        BCol,
        VueTimepicker
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    created() {
        this.init()
    },
    data() {
        return {
            state: true,
            mainPage: true,
            subPage: {
                auto: true,
                code: 0
            },
            logPage: {
                val: false,
                code: 0
            },
            deviceList: [],
            managePassword: '0202',
            settingDeviceList: [],
            feedList: [],
            errMsg: []
        }
    },
    methods: {
        async init() {
            try {
                this.state = null
                this.deviceList = []
                const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: 'mainPage'})
                
                const Data = res.data.data
                if(!Data.connect) return this.state = false
                if(Data.errMsg) {
                    this.errMsg = Data.errMsg
                    return this.state = 'err'
                }
                for(let i=0; i< Data.btnState.length; i++) {
                    this.deviceList.push({
                        able: (i === 0)? true: Data.active[i-1],
                        name: (i === 0)? '유압 제어': `급이#${i}`,
                        code: i,
                        auto: Data.autoState[i],
                        active: Data.btnState[i]
                    })
                }
                if(!this.mainPage) await this.getSetting()
                return this.state = true
            } catch (error) {
                console.log(error)
                return this.state = false
            }
        },
        async getSetting () {
            this.state = null
            this.settingDeviceList = []

            const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: `sub${this.subPage.auto? 'Auto': 'Mana'}D${this.subPage.code}`})
            const Data = res.data.data
            if(this.subPage.auto) {
                this.settingDeviceList.push({code: this.subPage.code, settings: []})
                const TimeList = await this.getTime(this.subPage.code)
                const DaysInfo = await this.getDays(this.subPage.code)
    
                for(let i=0; i < 5; i++) {
                    this.settingDeviceList[0].settings.push({
                            no: i + 1, 
                            active: Data.settings[i], 
                            time: TimeList[i].time, 
                            days: DaysInfo[i]
                    })
                }
            }
            else {
                this.settingDeviceList = Data.setList
                this.feedList = Data.feedList
            }
            return this.state = true
        },
        async getTime (deviceNum) {
            const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: `subTimeD${deviceNum}`})
            const Data = res.data.data
            return Data.timeList
        },
        async getDays (deviceNum) {
            const arr = []
            const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: `subDaysD${deviceNum}`})
            const Data = res.data.data
            const daysList = Data.daysList
            
            for(let num of daysList) {
                arr.push([
                    { text: '매일', val: num.days[0] },
                    { text: '월', val: num.days[1] },
                    { text: '화', val: num.days[2] },
                    { text: '수', val: num.days[3] },
                    { text: '목', val: num.days[4] },
                    { text: '금', val: num.days[5] },
                    { text: '토', val: num.days[6] },
                    { text: '일', val: num.days[7] },
                ])
            }
            return arr
        },
        async changePage(main) {
            const convertMain = !main
            this.mainPage = !main
            if(this.mainPage) return await this.init()
            else return await this.getSetting()
            return this.mainPage = convertMain
        },
        async changeSubPage (arg, val) {
            this.logPage.val = false
            this.subPage[arg] = val
            return await this.getSetting()
        },
        async changeLogPage (val) {
            this.subPage.auto = false
            return this.logPage['val'] = val
        },
        async activeBtn (btn, arg, val) {
            let command
            if(arg === 'auto') command = val ? `setAutoModeD${btn.code}` : `setManuModeD${btn.code}`
            else command = val ? `setManuOnD${btn.code}` : `setManuOffD${btn.code}`
            const value = 1
            await this.setVal(command, value)
            if(arg === 'auto') btn['active'] = false
            return btn[arg] = val
        },
        async setVal (command, val) {
            const result = await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: {
                command,
                commandValue: val,
            } })
            console.log(command, val)
            this.adaptVal(command, val)
            return result
        },
        async setFeedVal (cmd, val) {
            const Num = cmd.split('N')[1]
            this.$bvModal.msgBoxConfirm(<h4> 
              <b-input-group Append={'Kg'} class="mt-1" >
                <b-form-input id="rewriteBox" vModel={val} vOn:keyup_enter={document.getElementById('rewriteBox').blur()} type="number" style="width: 20%;" autofocus required/> 
              </b-input-group>
            </h4> , {
                refs: 'modalis',
            title: `사료량 변경 #${Num}`,
            titleTag: 'h4',
            noCloseOnBackdrop: true,
            okTitle: '수정',
            okVariant: 'warning',
            cancelTitle: '취소',
            centered: true,
            }).then(async ok => {
                if(ok) return await this.setVal(cmd, val * 10)
            })
        },
        async getFeedVal (deviceNum) {
            const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: `amountD${deviceNum}`})
        },
        adaptVal(command, val) {
            const target = (this.settingDeviceList.length > 0)? this.settingDeviceList[0].settings: null
            if(command.includes(`D${this.subPage.code}AutoN`)) {
                let cmd = command
                const N = cmd.replace(`D${this.subPage.code}AutoN`, '')
                return target[N-1].active = val? true: false
            }
            if(command.includes(`Y`)) {
                let cmd = command
                const N = cmd.substring(3, 4)
                const idx = cmd.substring(5)
                return target[N-1].days[idx].val =  val? true: false
            }
            if(command.includes('AmN')) {
                return this.feedList[command.split('N')[1] - 1].value = val / 10
            }
        },
        showTimeModal (input) {
            this.$refs[`timePickerModal_${input.code}_${input.no}`][0].show()
        },
        async setTime (deviceNum, autoNum, fullTime) {
            const hour = fullTime.split(':')[0]
            const minute = fullTime.split(':')[1]
            await this.setVal(`QD${deviceNum}N${autoNum}TH`, hour)
            await this.setVal(`QD${deviceNum}N${autoNum}TM`, minute)
            return
        }
    },
}

</script>

<style scoped>
.tableStyle {
    width: 100%;
    border: 0.2rem ridge;
    background-color: #E7ECF4;
    font-size: larger;
    color: black;
}
</style>