<template>
  <div style="height: inherit; position: relative; width: 100%;">
    <!-- <div class="fixed">
      <cctv-viewer
        ref="cctvViewer"
        @updateUI="updateCCTVHeight()"
      />
    </div> -->
    <div :style="style">
        <remote-control
          v-show="connect"
          ref="remoteControl"
          @updateFarm="updateFarm"
        />
        <error v-show="!connect"></error>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import RemoteControl from './remote-control/RemoteControl.vue'
import Error from '../pages/error/NotServerAccess.vue'
import store from '@/store'

export default {
  components: {
    /* 'cctv-viewer': CCTVViewer, */
    RemoteControl,
    VuePerfectScrollbar,
    Error
  },
  watch: {

  },
  created() {
    
  },
  data() {
    return {
      show: true,
      selectedFarm: [],
      selectedButton: [],
      farmOptions: [],
      buttonTypeOptions: [],
      dy: 50,
      height: 0,
      perfectScrollbarSettings: {
        scrollYMarginOffset: 0,
        suppressScrollX: true,
      },
    }
  },
  computed: {
    connect() {
      return store.getters['users/getConnectState']
    },
    style() {
      return {
        'z-index': 11,
      }
    },
    scrollStyle() {
      return {
        height: `${this.height}px`,
      }
    },
  },
  methods: {
    updateCCTVHeight() {
      setTimeout(() => {
        this.dy = parseFloat(getComputedStyle(document.querySelector('.fixed')).height)
        const contentHeight = parseFloat(getComputedStyle(document.querySelector('.content-overlay')).height)
        const navHeight = parseFloat(getComputedStyle(document.querySelector('.header-navbar-shadow')).height) + parseFloat(getComputedStyle(document.querySelector('.header-navbar-shadow')).padding)
        const footer = parseFloat(getComputedStyle(document.querySelector('.footer')).height)/15
        this.height = contentHeight - navHeight - footer - this.dy
      }, 500)
    },
    updateFarm(farmId) {
      
      /* this.$refs.cctvViewer.getCCTV(farmId)
      this.updateCCTVHeight() */
    },
  },
}
</script>

<style scoped>
.fixed {
  /* position: fixed; */
  display: block;
  z-index: 10;
  width: 100% !important;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100% !important;
}

</style>
