<template>
  <div>
    <b-card class="mb-0" >
      <b-row>
        <b-col
          cols="7"
          style="font-size:1.2em;font-weight:800;padding-right:0"
        >
          <v-select
            v-model="selectedFarm"
            :options="farmOptions"
            placeholder="축사"
            :clearable="false"
            :searchable="false"
          />
        </b-col>
        <b-col
          cols="5"
          style="font-size:1.2em;font-weight:800;padding-left:0;"
        >
          <v-select
            v-model="selectedButton"
            :options="buttonTypeOptions"
            placeholder="장치"
            :clearable="false"
            :searchable="false"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="connect && buttonItems.length > 1
      && selectedButton.value !== 'fan_trigger' 
      && selectedButton.value !== 'inverter' 
      && selectedButton.value !== 'inverter_hd' 
      && selectedButton.value !== 'inverter_hz' 
      && selectedButton.value !== 'feeder' 
      && selectedButton.value !== 'feeder_1button' 
      && selectedButton.value !== 'sprayer' 
      && selectedButton.value !== 'curtain_toggle'
      && selectedButton.value !== 'plc_fan_jnk'
      && selectedButton.value !== 'cooler_sh'
      && selectedButton.value !== 'curtain_sj'
      && selectedButton.value !== 'fan_control'
      && selectedButton.value !== 'fan_control_sj'
      && selectedButton.value !== 'inverter_control_sj'
      && selectedButton.value !== 'plc_manure_machine'
      && selectedButton.value !== 'plc_feeder'
      && selectedButton.value !== 'plc_inverter'
      && selectedButton.value !== 'plc_inverter_iv'
      && selectedButton.value !== 'farm_1'
      && selectedButton.value !== 'plc_curtain'">

      <div class="d-flex justify-content-between align-items-center" >
        <!-- all select checkbox -->
        <b-form-checkbox
        
          v-model="allChecked"
          switch
          style="margin-top:auto"
        >
          <strong style="font-size:1.6em"> 전체 </strong>
        </b-form-checkbox>
        <!-- <b-form-radio-group
          v-model="selectedAutomaticOption"
          :options="automaticOptions"
          button-variant="outline-primary"
          size="sm"
          buttons
        /> -->

          <b-form-radio-group
            v-if="openStopCloseButtons.includes(selectedButton.value)"
            v-model="selectedCommand"
            button-variant="outline-primary"
            :options="buttonOptions"
            :disabled="checkedItem.length === 0"
            size="lg"
            buttons
          />
          <b-form-radio-group
            v-if="workStopButtons.includes(selectedButton.value)"
            v-model="selectedCommand"
            button-variant="outline-primary"
            :options="inverterOptions"
            :disabled="checkedItem.length === 0"
            size="lg"
            buttons
          />
      </div>

      <!-- selected button control -->
      <div
        v-if="checkedItem.length > 0"
        class="text-center m-1"
      >
        <div class="divider my-1">
          <div class="divider-text text-warning">
            선택한 장치
          </div>
        </div>
        <b-form-radio-group
        v-if="checkedItemName.length"
          v-model="selectedCommand"
          :options="checkedItemName"
          disabled
          stacked
        />
        <!-- <b-form-radio-group
        v-if="checkedItemName.length > 4 && checkedItemName.length < 8"
          v-model="selectedCommand"
          :options="checkedItemName.slice(4, 8)"
          disabled
        />
        <b-form-radio-group
        v-if="checkedItemName.length > 8"
          v-model="selectedCommand"
          :options="checkedItemName.slice(8, 12)"
          disabled
        /> -->

        <!-- open / stop / close -->
        <!-- <b-form-group
          v-if="openStopCloseButtons.includes(selectedButton.value)"
        >
          <b-form-radio-group
            v-model="selectedCommand"
            button-variant="outline-primary"
            :options="buttonOptions"
            :disabled="checkedItem.length === 0"
            buttons
            class="p-1 d-flex"
          />
        </b-form-group> -->

        <!-- inverter: slider, work / etop -->
        <!-- <b-form-group
          v-if="workStopButtons.includes(selectedButton.value)"
        >
          <vue-slider
            v-if="selectedButton.value === 'inverter'"
            v-model="sliderValue"
            class="p-1 m-2 text-primary"
            :lazy="true"
            :max="60"
            tooltip="always"
            :tooltip-formatter="`${sliderValue} Hz`"
            :disabled="checkedItem.length === 0"
          />
          <b-form-radio-group
            v-model="selectedCommand"
            button-variant="outline-primary"
            :options="inverterOptions"
            :disabled="checkedItem.length === 0"
            buttons
            class="p-1 d-flex mt-2"
          />
        </b-form-group> -->
      </div>
    </b-card>

    <!-- control card -->
    <b-row>
      <b-col
        v-for=" item in buttonItems"
        :key="item._id"
        sm="12"
        md="6"
        lg="4"
      >
      <!-- {{ item.command }} -->
          <!-- :is-connect="item.connect" -->
        <control-card
          ref="controlCard"
          :key="item._id"
          :item="item"
          :has-checked="buttonItems.length"
          :prop-checked="allChecked"          
          @changeChecked="changeChecked"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox, BFormGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import ControlCard from './components/ControlCard.vue'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormRadioGroup,
    VueSlider,
    ControlCard,
    vSelect,
  },
  data() {
    return {
      updateTimer: null,
      connect: true,
      buttonConnect: Object([]),
      farmInfo: null,
      selectedFarm: [],
      selectedButton: [],
      farmOptions: [],
      buttonTypeOptions: [],

      selectedAutomaticOption: '',
      automaticOptions: [
        { text: '자동', value: true },
        { text: '수동', value: false },
      ],

      buttonItems: [],

      selectedCommand: '',
      buttonOptions: [
        { text: '열기', value: 'open' },
        { text: '중지', value: 'stop' },
        { text: '닫기', value: 'close' },
      ],
      inverterOptions: [
        { text: '동작', value: 'work' },
        { text: '중지', value: 'stop' },
      ],
      openStopCloseButtons: [
        'curtain', 'ceiling', 'ceiling_reverse'
      ],
      workStopButtons: [
        'inverter', 'inverter_hd', 'inverter_hz', 'feeder', 'light', 'fan', 'sprayer',
      ],
      sliderValue: 0,

      allChecked: false,
      checkedItem: [],
      checkedItemName: [],
      equipmentOf485: [
        'plc_feeder',
        'curtain_sj',
        'cooler_sh',
        'inverter',
        'inverter_hd',
        'inverter_hz',
        'fan_control',
        'fan_control_sj',
        'inverter_control_sj',
        'plc_inverter',
        'plc_inverter_iv',
        'plc_fan_jnk',
        'plc_manure_machine',
        'plc_curtain',
      ]
    }
  },
  watch: {
    async selectedFarm() {
      await store.dispatch('button/fetchButtons', { userId: getUserData().id })
      let customBtnTypeOptions = await store.getters['button/getButtonTypes'](this.selectedFarm.value)
      let out485TypeEquipments = customBtnTypeOptions.filter(e => !this.equipmentOf485.includes(e.value))
      let in485TypeEquipments = customBtnTypeOptions.filter(e => this.equipmentOf485.includes(e.value))
      let temp = []
      for(let btnOf485 of in485TypeEquipments) {
        let btns = await store.getters['button/getButtonInType'](btnOf485.value)
        for(let [idx, btn] of btns.entries()) {
          if(btn.farmId._id === this.selectedFarm.value) {
            temp.push({
              label: btn.name,
              value: `${btnOf485.value}|${idx}`
            })
          }
        }
      }
      /* for(let [index, button] of customBtnTypeOptions.entries()) {
        let idx = this.equipmentOf485.findIndex(e => e === button.value)
        if(idx > -1) {
          let btns = await store.getters['button/getButtonInType'](button.value)
          if(btns.length > 1) {
            customBtnTypeOptions.splice(index, 1)
            for(let [btn_idx, btn] of btns.entries()) {
              customBtnTypeOptions.push({
                label: btn.name,
                value: `${btn.type}_${btn_idx}`
              })
            }
          }
        }
      } */
      this.buttonTypeOptions = [
        ...out485TypeEquipments,
        ...temp
      ]

      if(getUserData().phone === '59119104') {
        this.buttonTypeOptions = await store.getters['button/getButtonTypes'](this.selectedFarm.value).filter(button => button.value !== 'ceiling_2button')
      }
      /* let inverter_hd = this.buttonTypeOptions.filter(button => button.value === 'inverter_hd')
      let inverter_ls = this.buttonTypeOptions.filter(button => button.value === 'inverter')
      let inverter_hz = this.buttonTypeOptions.filter(button => button.value === 'inverter_hz')
      if(inverter_hd.length > 1
      || inverter_hz.length > 1) {
        if(inverter_hd.length > 1) {
          this.buttonTypeOptions = this.buttonTypeOptions.filter(button => button.value !== 'inverter_hd')
        } else {
          this.buttonTypeOptions = this.buttonTypeOptions.filter(button => button.value !== 'inverter_hz')
        }
      } */


      //console.log('buttonTypeOptions', this.buttonTypeOptions)
      
      this.buttonItems = []
      this.initValue()
      if(this.buttonTypeOptions.findIndex(e=>e.value === 'curtain_toggle') > -1) this.buttonTypeOptions = this.buttonTypeOptions.filter(button => button.value !== 'curtain')
      this.selectedButton = this.buttonTypeOptions[0]
      this.$emit('updateFarm', this.selectedFarm.value)

    },
    async selectedButton() {
      /* let fetch_button = await store.dispatch('button/fetchButtons', { userId: getUserData().id })
      console.log(fetch_button) */
      this.initValue()
      this.getButtonList()
      await this.getConnect()
    },

    checkedItem() {
      this.selectedAutomaticOption = ''
      this.selectedCommand = ''
      this.sliderValue = 0
    },
    selectedAutomaticOption() {
      if (this.selectedAutomaticOption === '') return

      localStorage.setItem('lastAutoCommand', this.selectedAutomaticOption)

      const param = {
        ids: this.checkedItem,
        data: {
          isAuto: this.selectedAutomaticOption,
        },
      }
      this.updateData(param)
    },
    selectedCommand() {
      if (this.selectedCommand === '') return
      const param = {
        ids: this.checkedItem,
        data: {
          command: this.selectedCommand,
          commandValue: this.sliderValue,
        },
      }
      this.updateData(param)
    },
    sliderValue(newVal) {
      const param = {
        ids: this.checkedItem,
        data: {
          command: this.selectedCommand,
          commandValue: newVal,
        },
      }
      this.updateData(param)
    },
  },
  created() {
    this.getInfoBoardList()
    this.getFarmOptions()
    
  },
  mounted() {  
  },
  destroyed() {
    clearInterval(this.updateTimer)
  },

  methods: {
    sendToastMsg(msgType, msgContents) {
      this.$toast(msgContents, {
          position: 'top-right',
          timeout: 1000,
          icon: true,
          closeOnClick: true,
          type: msgType,
          rtl: false
        })
    },
    async getInfoBoardList () {
      const result = await store.dispatch('board/fetchBoard', {})
      this.boardList = result.data
      this.boardList.reverse()
      this.boardList = this.boardList.slice(0, 10)
      for(let board of this.boardList) {
          let idx = board.views.findIndex(e => e.id === getUserData().id)
          if(idx === -1) {
            this.sendToastMsg('warning', '공지사항을 확인해주세요!')
            return this.$router.push({ name: 'board-list', params: {} })
          }
      }
    },
    async getConnect() {
     /*  return new Promise( async resolve => {
        try{
          let buttonList = (await store.dispatch('users/getBtnState', { id: getUserData().id })).data.data
          for(let btn of this.buttonItems) {
            btn.connect = JSON.parse(buttonList.find(e => e.id === btn._id).state)
          }
        }
        catch (err) {
          //console.log(err)
          resolve(false)
        }
      })
       */
     /*  return this.connect */
    },
    initValue() {
      this.checkedItem = []
      this.allChecked = false
      this.selectedAutomaticOption = ''
      this.selectedCommand = ''
      this.sliderValue = 0
      
    },
    async getFarmOptions() {
      
      await store.dispatch('farm/fetchFarms', { userId: getUserData().id })
      this.farmOptions = await store.getters['farm/getFarmSelect']
      if (this.farmOptions.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedFarm = this.farmOptions[0]
      }
    },
    async getButtonList() {
      this.buttonItems = []
      await store.dispatch('button/fetchButtons', { userId: getUserData().id })
      if(this.selectedButton.value.includes('|')) this.selectedButton.value = this.selectedButton.value.split('|')[0]
      let list = await store.getters['button/getButtonInType'](this.selectedButton.value)
      if(this.equipmentOf485.includes(this.selectedButton.value)) list = [list.find(e => e.name === this.selectedButton.label)]
      for(let i of list) {
        if(i.farmId.name === this.selectedFarm.label) {
          this.buttonItems.push(i)
          this.farmInfo = i
        }
      }
      if (this.selectedButton.value === 'feeder' || this.selectedButton.value === 'feeder_1button') {
        const feederBtnList = [
          ...await store.getters['button/getButtonInType']('hydraulic_trigger'),
          ...await store.getters['button/getButtonInType']('hydraulic_complex'),
          ...await store.getters['button/getButtonInType']('feeder_necklace'),
          ...await store.getters['button/getButtonInType']('hydraulic_trigger2'),
          ...await store.getters['button/getButtonInType']('hydraulic_1trigger'),
        ]
        
        for(let j of feederBtnList) {
          if(j.farmId.name === this.selectedFarm.label) {
            this.buttonItems = this.buttonItems.concat(j)
            if(j.type === 'feeder' || j.type === 'hydraulic_trigger2') j.command = ''
          }
        }
      }
      
      /* if(this.selectedButton.value === 'inverter') {
        const inverterBtnList = [
          ...await store.getters['button/getButtonInType']('inverter_hd'),
          ...await store.getters['button/getButtonInType']('inverter_hz'),
        ]
        
        for(let j of inverterBtnList) {
          if(j.farmId.name === this.selectedFarm.label) this.buttonItems = this.buttonItems.concat(j)
        }
      } */
      if (this.selectedButton.value === 'curtain_toggle') {
        const curtainItems = await store.getters['button/getButtonInType']('curtain')
        for(let u of curtainItems) {
          if(u.farmId.name === this.farmInfo.farmId.name) {
            this.buttonItems = this.buttonItems.concat(u)
          }
        }
        this.buttonTypeOptions = this.buttonTypeOptions.filter(button => button.value !== 'curtain')
      }
      

      if (this.selectedButton.value === 'fan' || this.selectedButton.value === 'fan_trigger') {
        const fansettingItems = await store.getters['button/getButtonInType']('fan_setting')
        this.buttonItems = this.buttonItems.concat(fansettingItems)
      }
      if (getUserData().phone === '59119104' && this.selectedButton.value === 'ceiling_reverse') {
        const ceilingItems = await store.getters['button/getButtonInType']('ceiling_2button')
        for(let u of ceilingItems) {
          if(u.farmId.name === this.farmInfo.farmId.name) this.buttonItems = this.buttonItems.concat(u)
        }
      }
    },
    changeChecked(buttonId, buttonName, newVal) {
      if (newVal) {
        this.checkedItem.push(buttonId)
        this.checkedItemName.push(buttonName)
      } else {
        this.checkedItem.splice(this.checkedItem.indexOf(buttonId), 1)
        this.checkedItemName.splice(this.checkedItemName.indexOf(buttonName), 1)
      }
    },
    async updateData(param) {
      await store.dispatch('button/updateButtonMany', { queryBody: param })
      await store.dispatch('button/fetchButtons', { userId: getUserData().id })
      await this.getButtonList()
      //this.initValue()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
