<template>
    <div>
        <div v-show="state === 'Local'">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
              <feather-icon
                icon="AlertTriangleIcon"
                style="color:red;"
                size="30"/>
              <br><br>
              <b>원격 조작 모드가 아닙니다.</b><hr>
              <b-button @click="init">재연결</b-button>
            </h3>
        </div>
        <div v-show="state === null" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
                <br><br>
                <b>잠시만 기다려주세요...</b>
            </h3>
        </div>
        <div v-show="state === false" class="mt-1">
            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                <h4>접속 불가</h4><hr>
                <feather-icon
                    icon="AlertTriangleIcon"
                    style="color:red;"
                    size="45"/>
                <hr>
                <b>점검 사항</b>
                <hr>
                <p>1) 장비 전원</p>
                <p>2) 인터넷 연결</p>
                <p>3) 네트워크 라인</p>
            </h3>
            <br>
            <b-button variant="info" @click="init">재연결</b-button>
            <hr>
        </div>
        <div v-show="state == true">
            <b-button-group  
                v-for="page of [
                    {text: `메인`, value: true}, 
                    {text: `설정`, value: false},
                ]" 
                :key="`Mode${page.value? 'Main': 'Sub'}`"      
                style="width: 50%;" >
                <!-- :disabled="page.value === 'Setting'" -->
                    <b-button 
                    :style="`margin-left: 0.3rem; margin-right: 0.3rem; border: 2px ${(main == page.value)?'inset':'outset'}`"
                    :variant="`${(main == page.value)?'primary':''}`"
                    @click="changePage(page.value)"> 
                        {{page.text}}
                    </b-button>
            </b-button-group>
            <div>
                <div v-show="sensor.temp != 0 && sensor.humi != 0">
                    <div class="divider">
                        <div class="divider-text text-primary">
                            센서 현황
                        </div>
                    </div>
                    <b-row style="font-size: larger; font-weight: bold">
                        <b-col>
                            <feather-icon
                                size="20"
                                icon="ThermometerIcon"
                            /> 
                            <span style="color: red">
                                {{ sensor.temp || '-' }} ℃
                            </span>
                        </b-col>
                        <b-col>
                            <feather-icon
                                size="20"
                                icon="DropletIcon"
                            />
                            <span style="color: rgb(77, 163, 197)">
                                {{ sensor.humi || '-' }} ％
                            </span>
                        </b-col>
                        <b-col>
                            <feather-icon
                                size="20"
                                icon="CloudRainIcon"
                            />
                            <span style="color: blue">
                                {{ sensor.rain === 'Rain'? '비 감지': '-' }}
                            </span>
                        </b-col>
                    </b-row>
                    <hr>
                </div>
                <div v-show="main">
                    <div v-for="(device, idx) in chList" :key="`${device.name}_${idx}`">
                        <b-card 
                        no-body
                        v-if="device.able"
                        style="background-color: #E7ECF4; border: 2px ridge;">
                            <template #header>
                                <div 
                                    v-if="idx === 0" 
                                    style="text-align: center; font-weight: bold; font-size: x-large; width: -webkit-fill-available">
                                    <b>전체 모드</b>
                                </div>
                                <div 
                                    v-if="idx !== 0"
                                    style="text-align: center; font-weight: bold; font-size: x-large; width: -webkit-fill-available">
                                    <b> CH0{{ device.name }}</b>
                                </div>
                            </template>
                            <hr style="border: 2px inset; margin-top: 0">
                            <div>
                                <b-button-group  
                                    v-for="mode of [{text: `${(idx === 0)?'전체 자동':'자동'}`, value: 'Auto'}, {text: `${(idx === 0)?'전체 수동':'수동'}`, value: 'Manual'}]" 
                                    :key="`${device.name} Mode_${mode.value}`" 
                                    style="width: 45%; margin-bottom: 1rem;">
                                        <b-button 
                                        size="sm"
                                        @click="setVal(`${idx}Ch${mode.value}`, (idx != 0 && mode.value == 'Manual')? 0: 1)"
                                        :disabled="!device.able"
                                        :variant="`${(mode.value === device.mode)? 'primary': ''}`" 
                                        style="font-size: 1.4rem; border: 2px outset; margin-right: 0.5rem; margin-left: 0.5rem;"> 
                                            {{mode.text}}
                                        </b-button>
                                </b-button-group>
                            </div>
                            <div>
                                <b-button-group  
                                    v-show="idx !== 0 || device.mode === 'Manual'"
                                    v-for="work of [
                                        {text: '열림', value: 'Open'},
                                        {text: '정지', value: 'Stop'},
                                        {text: '닫힘', value: 'Close'},
                                    ]" 
                                    :key="`${device.name} State_${work.value}`" 
                                    style="width: 30%; margin-bottom: 1rem;">
                                        <b-button 
                                        pill
                                        :disabled="device.mode === 'Auto'"
                                        @click="setVal(`${idx}Ch${work.value}`, 1)"
                                        :variant="`${(work.value === device.state)? 'danger': ''}`" 
                                        style="font-size: large; border: 2px outset; margin-right: 0.5rem; margin-left: 0.5rem;"> 
                                            {{work.text}}
                                        </b-button>
                                </b-button-group>
                            </div>
                        </b-card>
                    </div>
                </div>
                <div v-show="!main" >
                    <div >
                        <div class="divider">
                            <div class="divider-text text-primary">
                                CH 선택
                            </div>
                        </div>
                        <div>
                            <b-button-group  
                                v-show="dIdx <= 4 && dIdx > 0"
                                v-for="(device, dIdx) in chList" :key="`1${device.name}_${dIdx}`"
                                style="width:25%; margin-bottom: 0.7rem;">
                                    <b-button 
                                    size="sm"
                                    :disabled="!device.able"
                                    @click="changeCH(dIdx)"
                                    :variant="`${(device.name === setTarget.name)?'primary': ''}`" 
                                    style="border: 2px outset; margin-right: 0.3rem; margin-left: 0.3rem;"> 
                                        CH0{{device.name}}
                                    </b-button>
                            </b-button-group>
                            <br>
                            
                        </div>
                        <div>
                            <b-button-group  
                                v-show="dIdx > 4"
                                v-for="(device, dIdx) in chList" :key="`2${device.name}_${dIdx}`"
                                style="width:25%; margin-top: 0.7rem;">
                                    <b-button 
                                    size="sm"
                                    :disabled="!device.able"
                                    @click="changeCH(dIdx)"
                                    :variant="`${(device.name === setTarget.name)?'primary': ''}`" 
                                    style="border: 2px outset; margin-right: 0.3rem; margin-left: 0.3rem;"> 
                                        CH0{{device.name}}
                                    </b-button>
                            </b-button-group>
                        </div>
                        <div style="background-color: #E7ECF4;" v-if="!main && setTarget.name">
                            <hr>
                            <h4> CH0{{setTarget.name}} 제어 순위 설정</h4>
                            <table style="width: -webkit-fill-available; text-align: center; font-weight: bold; font-size: large;">
                                <tr 
                                @click="changeRank(opt)"
                                v-for="(opt) of setTarget.rank" 
                                :key="opt.type">
                                    <td colspan=""> {{ opt.name }} </td>
                                    <td>
                                        <span style="border: 2px outset; background-color: white;"> 
                                            {{ `${(opt.rank)? opt.rank+' 순위': '사용 안함'}` }} 
                                        </span>  
                                    </td>
                                </tr>
                            </table>
                            <hr>
                            <h4> CH0{{setTarget.name}} 온/습도 설정</h4>
                            <table style="width: -webkit-fill-available; text-align: center; font-weight: bold; font-size: large;">
                                <tr>
                                    <th></th>
                                    <th>열림</th>
                                    <th>닫힘</th>

                                </tr>
                                <tr>
                                    <td>온도</td>
                                    <td @click="msgBox(setTarget.sensor[0])">
                                        <span style="border: 2px outset; background-color: white;"> 
                                            {{ setTarget.sensor[0].val }}
                                        </span>℃
                                    </td>
                                    <td @click="msgBox(setTarget.sensor[1])">
                                        <span style="border: 2px outset; background-color: white;"> 
                                            {{ setTarget.sensor[1].val }}
                                        </span>℃
                                    </td>
                                </tr>
                                <tr >
                                    <td>습도</td>
                                    <td @click="msgBox(setTarget.sensor[2])">
                                        <span style="border: 2px outset; background-color: white;"> 
                                            {{ setTarget.sensor[2].val }}
                                        </span>％
                                    </td>
                                    <td @click="msgBox(setTarget.sensor[3])">
                                        <span style="border: 2px outset; background-color: white;"> 
                                            {{ setTarget.sensor[3].val }}
                                        </span>％
                                    </td>
                                </tr>
                            </table>
                            <hr>
                            <h4> CH0{{setTarget.name}} 스케쥴 설정</h4>
                            <table style="display:inline ;text-align: center; font-weight: bold; font-size: larger;" >
                                <thead>
                                    <tr>
                                        <th>
                                            No
                                        </th>
                                        <th>
                                            -
                                        </th>
                                        <th>시간</th>
                                    </tr>
                                </thead>
                                <tbody v-for="opt of setTarget.timerList" :key="opt.type">
                                    
                                    <tr >
                                        <td rowspan="2">{{opt.name}}</td>
                                        <td rowspan="2">
                                            <b-form-checkbox switch v-model="opt.active" @change="changeTimeActive(opt)" >
                                            </b-form-checkbox>
                                        </td>
                                        <td >
                                            <b-input-group append="열림">
                                                <b-form-input 
                                                @change="changeTime(opt, 'Srt')"
                                                id="timeVal" 
                                                v-model=opt.start 
                                                type="time" 
                                                :style="`font-size: larger; font-weight:${opt.active?'bold':''};`" /> 
                                            </b-input-group>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <td >
                                            <b-input-group append="닫힘">
                                                <b-form-input 
                                                @change="changeTime(opt, 'End')" 
                                                id="timeVal" 
                                                v-model=opt.end 
                                                type="time" 
                                                :style="`font-size: larger; font-weight:${opt.active?'bold':''};`" /> 
                                            </b-input-group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
                                <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
                                <br><br>
                                <b>잠시만 기다려 주세요...</b>
                                <br>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    BTime,
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BTable,
    BSpinner,
    BFormCheckbox,
    BBadge,
    BInputGroup,
    BFormInput,
    BCard,
    BModal
} from 'bootstrap-vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import store from '@/store'

export default {
    components: {
        BTime,
        BRow,
        BCol,
        BSpinner,
        BButtonGroup,
        BButton,
        BTable,
        BFormCheckbox,
        BInputGroup,
        BFormInput,
        BBadge,
        BCard,
        BModal,
        VueTimepicker
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            state: null,
            main: true,
            chList: [],
            sensor: {
                temp: 1,
                humi: 1,
                rain: true
            },
            setTarget: {
                name: 1
            }
        }
    },
    created() {
        this.init()

    },
    computed: {
        
    },
    watch: {
    },
    methods: {
        async init() {
            this.state = null
            
            this.getData()
            
        },
        async getData() {
            try {
                const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: 'main'})
                if(res.data.status === 'success' && res.data.data.mode === 'Local') return this.state = 'Local'
                if(res.data.status === 'success' && res.data.data.connect) {
                    const sensors = res.data.data.sensorInfo
                    this.sensor.temp = sensors.temperature
                    this.sensor.humi = sensors.humidity
                    this.sensor.rain = sensors.rain
                    const btnInfo = res.data.data.btnInfo
                    this.chList = []
                    for(let btn of btnInfo) this.chList.push(btn)
                    return this.state = true
                }
                return this.state = false
            } catch (error) {
                return this.state = false
            }
        },
        changePage (val) {
            if(val == true) this.init()
            else this.changeCH(this.setTarget.name)
            return this.main = val
        },
        async changeCH(i) {
            this.setTarget.name = null
            const res = await store.dispatch('button/getStateByCommand', { id: this.buttonItem._id, command: `sub${i}`})
            if(res.data.status === 'success' && res.data.data.connect) this.setTarget = res.data.data
            this.setTarget.name = i
            return 
        },
        async setVal (command, val) {
            const result = await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: {
                command,
                commandValue: val,
            }})
            if(result.data.status === 'success') this.adaptVal(command, val)
            return result
        },
        async adaptVal(command){
            const name = command.split('Ch')[0]
            const cmd = command.split('Ch')[1]
            if(name == 0 && cmd === 'Manual') for(let ch of this.chList) ch.mode = cmd, ch.state = 'Stop'
            if(name == 0 && cmd === 'Auto') {
                for(let ch of this.chList) ch.mode = cmd, ch.state = 'Stop'
                setTimeout(async () => this.getData(), 500);
            }
            if(name == 0 && (cmd === 'Open' || cmd === 'Stop' || cmd === 'Close')) {
                for(let ch of this.chList) ch.state = 'Stop'
                setTimeout(() => {
                    for(let ch of this.chList) ch.state = cmd
                }, 1000);
            }
            if(name != 0 && (cmd === 'Auto' || cmd === 'Manual')) {
                this.chList[0].mode = '' 
                this.chList[name].mode = cmd
                this.chList[name].state = 'Stop'
                setTimeout(async () => this.getData(), 500);
            }
            if(name != 0 && (cmd === 'Open' || cmd === 'Stop' || cmd === 'Close')) {
                this.chList[0].mode = ''
                this.chList[0].state = ''
                this.chList[name].state = 'Stop'
                setTimeout(() => this.chList[name].state = cmd, 1000);
            }
        },
        async changeRank(target) {
            if(target.rank != 0) {
                if(target.rank == 1) {
                    for(let no of this.setTarget.rank) {
                        await this.setVal(`Q${this.setTarget.name}ChRank${this.getValName(no.name)}`, 0)
                        no.rank = 0
                    } return
                }
                else {
                    await this.setVal(`Q${this.setTarget.name}ChRank${this.getValName(target.name)}`, 0)
                    return target.rank = 0
                }
            }
            let defaultRank = [1,2,3,4]
            for(let no of this.setTarget.rank) {
                const idx = defaultRank.findIndex(el => el == no.rank)
                if(idx > -1) defaultRank.splice(idx, 1)
            }
            target.rank = defaultRank[0]
            return await this.setVal(`Q${this.setTarget.name}ChRank${this.getValName(target.name)}`, target.rank)
        },
        getValName(name) {
            switch (name) {
                case '온도': return 'Temp'
                case '습도': return 'Humi'
                case '시간': return 'Sche'
                case '강우': return 'Rain'
                case 'AutoTempOpen': return '온도 열림'
                case 'AutoTempClose': return '온도 닫힘'
                case 'AutoHumiOpen': return '습도 열림'
                case 'AutoHumiClose': return '습도 닫힘'
            }
        },
        async changeTime(target, val) {
            const hour = await this.setVal(`Q${this.setTarget.name}ChSche${target.name}${val}H`, target[(val==='Srt')?'start':'end'].split(':')[0])
            const minute = await this.setVal(`Q${this.setTarget.name}ChSche${target.name}${val}M`, target[(val==='Srt')?'start':'end'].split(':')[1])
        },
        async changeTimeActive(target, val) {
            //console.log(target, val)
            return await this.setVal(`${this.setTarget.name}ChSche${target.name}Able`, target.active?1 :0)
        },
        async msgBox(target) {
            let value = ''
            this.$bvModal.msgBoxConfirm(<h4> 
                <b-input-group class="mt-1" >
                    <b-form-input id="valInput" vModel={value} vOn:keyup_enter={document.getElementById('valInput').blur()} type="number" style="width: 20%;" autofocus required/> 
                </b-input-group>
            <br></br>
            ※ 소수점 자리는 사용이 불가합니다. ※
            </h4> , {
                refs: 'modalis',
                title: `"${this.getValName(target.name)}" 설정 수정`,
                titleTag: 'h4',
                noCloseOnBackdrop: true,
                okTitle: '수정',
                okVariant: 'warning',
                cancelTitle: '취소',
                centered: true,
            }).then(async ok => {
                if(ok) {
                    await this.setVal(`Q${this.setTarget.name}Ch${target.name}`, value * 100)
                    return target.val = value
                }
                
            })
        },
    },
}
</script>

<style scoped>
div.col {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
}
p {

    font-size: large; 
    font-weight: bold; 
    background-color: lightgray;
    
}
.div {
    overflow: auto; /* 내용이 넘칠 경우 스크롤바 표시 */
}


</style>