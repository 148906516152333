<template>
  <b-card  
  style="border: 0.15rem ridge #ADADAD;"
  bg-variant="light-secondary">
    <b-card-header 
    header-bg-variant="light"
    style="border: 0.15rem inset; padding-bottom: 0.3rem;/* background-image: linear-gradient(to bottom, #FFE3AA, #FFFFFF) */;"
    >


      <!--   버튼 상단   -->
      <b-card-title class="d-flex " >
        <!--   일괄 동작 장비 선택 Switch   -->
        <b-form-checkbox
        v-if="buttonItem.type !== 'feeder' 
        && buttonItem.type !== 'feeder_1button' 
        && buttonItem.type !== 'hydraulic'
        && buttonItem.type !== 'hydraulic_1trigger'
        && buttonItem.type !== 'farm_1'
        && (userInfo.phone !== '59119104' || buttonItem.type !== 'ceiling_2button') 
        && !isAuto
        && hasChecked > 1"
          ref="checkbox"
          v-model="checked"
          switch
          style=""
        >
        </b-form-checkbox>
        <!--   장비 이름 && 수정 버튼   -->
        <div   @click="showModal">
          <b style="
            font-size: large;
            font-weight: bold;
            color: black;
            text-decoration: dotted; 
            text-decoration-line: underline; 
            text-decoration-color: orange;"> 
              {{ buttonItem.name }} </b> 
          <!-- <feather-icon
            icon="Edit2Icon"
            size="16"
            style="color: orange"
          /> -->
        </div>
      </b-card-title>
      
      <div class="d-flex justify-content-end mt-0 mb-0"  >
        
        <b-badge
          v-if="btnsEx.equipmentOf485.indexOf(buttonItem.type) === -1"
          class="mt-0 mb-0 mr-1 cursor-pointer"
          style="margin: auto; border: 2px outset; align-items: right;"
          variant="light-dark"
          @click="getLogForm"
          >
          <feather-icon
            icon="FileTextIcon"
            size="20"
          />
          <span>기록</span>
        </b-badge>
        <text-log-form
          ref="textLogForm"
          :buttonItem="buttonItem"
        ></text-log-form>

        <b-badge
        v-show="btnsEx['allowAutoBtns'].includes(buttonItem.type)"
        v-model="isAuto"
        class="mt-0 mb-0 mr-1 cursor-pointer"
          style="margin: auto; border: 2px outset; background-color: #E1E1E1;"
          :variant="isAutoColor"
          @click="toggleAuto">
          <feather-icon
            v-if="!isAuto"
            icon="PowerIcon"
            size="20"
          />
          <b-spinner
            v-else
            type="grow"
            small
            style="width: 0.5rem; height: 0.5rem; margin-bottom: 0.3rem;"   
          />
          <span> 자동화 {{(isAuto)? '실행': '정지'}}</span>
        </b-badge>
      </div>
    </b-card-header>
    <hr style="margin: 0;">
    <b-card-body
      class="text-center"
    >
      
      
      <b-form-group v-if="!isAuto" style="margin:0">
        <b-form-radio-group
          v-if="openStopCloseButtons.includes(buttonItem.type) "
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="buttonOptions"
          buttons
          :disabled="isDisable"
          class="d-flex"
          size="lg"
        />

        <div v-if="inverterDevices.includes(buttonItem.type)">
          <inverter-series
            :buttonItem="buttonItem"
          ></inverter-series>
        </div>

        <!-- work / stop -->
        <b-form-radio-group
          v-if="workStopButtons.includes(buttonItem.type)"
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="inverterOptions"
          buttons
          :disabled="isDisable"
          class="d-flex"
          size="lg"
        />
        
        <!-- work -->
        
        <b-form-radio-group
          v-if="workBtns.includes(buttonItem.type)"
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="feeder1Options"
          :disabled="isDisable"
          buttons
          class="d-flex"
          size="lg"
        />
        <b-form-radio-group
          v-if="buttonItem.type==='feeder_1button' 
          || buttonItem.type === 'fan_trigger' 
          || buttonItem.type === 'hydraulic_1trigger'" 
          v-model="selected"
          button-variant="outline-primary"
          :options="feeder1Options"
          :disabled="isDisable"
          buttons
          class="d-flex"
          size="lg"
          @change="selectedWork('work')"
        />

        <!-- open / close -->
        <b-form-radio-group
          v-if="buttonItem.type==='ceiling_2button' "
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="ceilingOptions"
          :disabled="isDisable"
          buttons
          class="d-flex"
          size="lg"
        />

        
         <b-form-radio-group
          v-if="buttonItem.type === 'curtain_toggle'"
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="connDisconn"
          :disabled="isDisable"
          buttons
          class="d-flex"
          size="lg"
        />

        <b-form-radio-group
          v-if="buttonItem.type==='hydraulic_trigger'"
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="hydraulicOptions"
          :disabled="isDisable"
          buttons
          class="d-flex"
          size="lg"
        />

        <b-form-radio-group
          v-if="buttonItem.type=== 'fan_setting'"
          v-model="selectedButton"
          button-variant="outline-primary"
          :options="fanSettingOptions"
          buttons
          class="d-flex"
          size="lg"
        />

      </b-form-group>

      <!-- Fan Control  -->
      <div v-if="buttonItem.type === 'fan_control' 
        || buttonItem.type === 'fan_control_sj'
        || buttonItem.type === 'inverter_control_sj'">
          <fan-control
            :buttonItem="buttonItem"
          ></fan-control>
      </div>

      <!-- 스마트 냉각기 (주) 삼호 -->
      <div v-if="buttonItem.type === 'cooler_sh'">
        <cooler-sh
          :buttonItem="buttonItem"
        ></cooler-sh>
      </div>

      <!-- PLC INVERTER FAN -->
      <div v-if="buttonItem.type === 'plc_inverter'
      || buttonItem.type === 'plc_inverter_iv'">
        <plc-inverter
          :buttonItem="buttonItem"
        ></plc-inverter>
      </div>

      <!-- PLC INVERTER FAN - JNK -->
      <div v-if="buttonItem.type === 'plc_fan_jnk'">
        <plc-fan-jnk
          :buttonItem="buttonItem"
        ></plc-fan-jnk>
      </div>

      <!-- PLC FEEDER - JNK -->
      <div v-if="buttonItem.type === 'plc_feeder'">
        <plc-feeder
          :buttonItem="buttonItem"
        ></plc-feeder>
      </div>

      <!-- PLC Curtain - JNK -->
      <div v-if="buttonItem.type === 'plc_curtain'">
        <plc-curtain
          :buttonItem="buttonItem"
        ></plc-curtain>
      </div>

      <!-- PLC MANURE TREATMENT MACHINE - JNK -->
      <div v-if="buttonItem.type === 'plc_manure_machine'">
        <plc-manure-machine
          :buttonItem="buttonItem"
        ></plc-manure-machine>
      </div>

      <automatic-list
        v-if="isAuto"
        ref="automaticList"
        :button-id="buttonItem._id"
        :slider="sliderValue"
        :fanWork="selectedFanType"
        @isAutoFalse="isAutoFalse"
      />

      
    </b-card-body>
    

    <!-- modal -->
    <b-modal
      ref="modifyNameModal"
      title="장치명 변경"
      ok-title="수정"
      ok-variant="warning"
      cancel-title="취소"
      cancel-variant="secondary"
      centered
      @ok="modifyName"
    >
      <form>
        <b-form-input
            id="name-input"
            v-model="name"
            required
            
          />
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, 
  BCardHeader, 
  BCardTitle, 
  BCardBody, 
  BModal, 
  BFormInput, 
  BFormGroup, 
  BFormRadioGroup, 
  BCardText, 
  BFormCheckbox, 
  BProgress, 
  BSpinner, 
  BBadge, 
  BInputGroup, 
  BFormSelect,
  BAvatar, 
  BCollapse, 
  BTable, 
  BButton, 
  BButtonGroup,

} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'
import { getDateString } from '@core/utils/utils'
import { heightFade } from '@core/directives/animations'
import AutomaticList from './AutomaticList.vue'
import PlcManureMachine from './equipments/PlcManureMachine.vue'
import PlcInverter from './equipments/PlcEInverter.vue'
import PlcFanJnk from './equipments/PlcInverter_t.vue'
import PlcFeeder from './equipments/PlcFeeder_t.vue'
import PlcCurtain from './equipments/PlcCurtain_t.vue'
import CoolerSh from './equipments/Cooler.vue'
import FanControl from './equipments/FanControl.vue'
import InverterSeries from './equipments/Inverter.vue'
import CurtainControl from './equipments/CurtainControl.vue'
import { getUserData } from '@/auth/utils'
import TextLogForm from './forms/TextLogForm.vue'

export default {
  components: {
    // BAlert,
    InverterSeries,
    CurtainControl,
    PlcFeeder,
    PlcCurtain,
    CoolerSh,
    FanControl,
    PlcManureMachine,
    PlcInverter,
    PlcFanJnk,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BModal,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BCardText,
    VueSlider,
    AutomaticList,
    BFormCheckbox,
    BProgress,
    BSpinner,
    BBadge,
    BInputGroup,
    BFormSelect,
    BAvatar ,
    BCollapse,
    BTable,
    BButton,
    BButtonGroup,
    TextLogForm
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    propChecked: {
      type: Boolean,
    },
    hasChecked: {
      type: Number
    },
    /* isOn: {
      type: Object,
    }, */
    isConnect: {
      type: Boolean,
    },
  },
  data() {
    return {
      isDisable: false,
      btnsEx: {
        allowAutoBtns: [
          'curtain', 
          
          'ceiling', 
          'ceiling_2button', 
          'ceiling_reverse', 

          'fan', 'light', /* 'fan_trigger',  */
          'inverter', 'inverter_hd', 'inverter_hz',

          'feeder', 
          'feeder_1button', 
          'hydraulic_complex', 
          'hydraulic_trigger', 
          'hydraulic_trigger2', 
          'feeder_necklace',
          
          'sprayer', 'sprayer_trigger',
        ],
        equipmentOf485: [
          'cooler_sh',
          'inverter',
          'inverter_hd',
          'inverter_hz',
          'fan_control',
          'fan_control_sj',
          'inverter_control_sj',
          'plc_inverter',
          'plc_inverter_iv',
          'plc_fan_jnk',
          'plc_feeder',
          'plc_manure_machine',
          'plc_curtain'
        ],
        allowCheckedBtns: [
          'curtain'
        ],
        btnNameLimit: 6
      },
      updateTimer: null,
      connect: this.buttonItem.connect,
      userInfo: null,
      modifyNameModal: false,
      isAlert: true,
      alertIcon: 'BellIcon',
      alertColor: 'text-warning',
      buttonOptions: [
        { text: '열기', value: 'open' },
        { text: '정지', value: 'stop' },
        { text: '닫기', value: 'close' },
      ],
      ceilingOptions: [
        { text: '열기', value: 'open' },
        { text: '닫기', value: 'close' },
      ],
      inverterOptions: [
        { text: '동작', value: 'work' },
        { text: '정지', value: 'stop' },
      ],
      hydraulicOptions: [
        { text: '급여', value: 'open' },
        { text: '정지', value: 'stop' },
        { text: '막음', value: 'close' },
      ],
      fanSettingOptions: [
        { text: '자동', value: 'open'},
        { text: '수동', value: 'close'},        
      ],
      
      feeder1Options: [
        { text: '동작', value: 'work'},
      ],
      openStopCloseButtons: [
        'curtain', 'ceiling', 'ceiling_reverse', 'feeder_necklace'
      ],
      inverterDevices: [
        'inverter', 'inverter_hd', 'inverter_hz'
      ],
      connDisconn: [
        { text: '연결', value: 'work' },
        { text: '해제', value: 'stop' },
      ],
      workStopButtons: [
        'feeder', 
        'light', 
        'fan',  
        'sprayer', 
        'sprayer_trigger', 
        'car_disinfector_trigger', 
        'car_disinfector_toggle', 
        'hydraulic_complex', 
        'hydraulic_trigger2'
      ],
      workingTime: '',
      title: '',
      name: this.buttonItem.name,
      selected: (this.buttonItem.type === 'feeder_1button' || this.buttonItem.type === 'fan_trigger' || this.buttonItem.type === 'hydraulic_1trigger') ? '' : this.selectedButton,
      selectedFanType: 'get',
      workBtns: [],
      interval: []
    }
  },
  setup(props) {
    const buttonItem = props.item
    const { command, commandValue, isAuto } = buttonItem
    const autoColor = isAuto ? 'text-danger' : 'text-musted'
    const isAutoColor = isAuto ? 'light-success' : 'light-dark'
    const selectedButton = command
    const sliderValue = commandValue
    return {
      buttonItem,
      isAuto,
      autoColor,
      selectedButton,
      sliderValue,
      isAutoColor,
    }
  },
  created() {
    this.userInfo = getUserData()
  },
  mounted() { 
    if(this.buttonItem.type === 'farm_1') {
      if((this.buttonItem.buttonSetting.relayCount === 1 && this.buttonItem.buttonSetting.signalType === 'trigger')) {
        this.workBtns.push('farm_1')
        this.selected = ''
        this.selectedButton = ''
      }

      if((this.buttonItem.buttonSetting.relayCount === 1 && this.buttonItem.buttonSetting.signalType === 'toggle')
      || (this.buttonItem.buttonSetting.relayCount === 2 && this.buttonItem.buttonSetting.signalType === 'trigger')) {
        this.workStopButtons.push('farm_1')
      }

      if((this.buttonItem.buttonSetting.relayCount === 2 && this.buttonItem.buttonSetting.signalType === 'complex')
      || (this.buttonItem.buttonSetting.relayCount === 3 && this.buttonItem.buttonSetting.signalType === 'trigger')) {
        this.openStopCloseButtons.push('farm_1')
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.updateTimer)
    //clearInterval(this.interval)
    for(let interval of this.interval) clearInterval(interval.intervalId)
  },
  computed: {
    notSupportedRange () {
      if(this.cFanRPM > 0 && this.cFanRPM < 30) return this.sendToastMsg('warning', `해당 범위는 선택하실 수 없습니다.`)
    },
    /* connect: {
      get() {
        return this.isOn
      },
    }, */
    checked: {
      get() {
        if((getUserData().phone === '59119104' && this.buttonItem.type === 'ceiling_2button') || this.isAuto){
          this.propChecked = false
        }
        return this.propChecked//, this.isOn
      },
      set(newVal) {
        this.$emit('changeChecked', this.buttonItem._id, this.buttonItem.name, newVal)
      },
    },
  },
  watch: {
  
    selectedButton(newVal) {
      //if(this.buttonItem.command !== newVal) this.isDisable = true
      this.isDisable = true
      const param = {
        command: newVal,
        commandValue: this.sliderValue,
      }
      if(param.command === '') return this.isDisable = false
      store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
        .then(res => {
          this.isDisable = false
          if(!res.data) return this.sendToastMsg('warning', `${this.buttonItem.name} 장치가 응답하지 않았습니다.`)
          this.updateData()
          this.sendToastMsg('success', `${this.buttonItem.name} ${this.getCommandName(newVal)}하였습니다.`)
          if(this.buttonItem.type === 'feeder' || this.buttonItem.type === 'hydraulic_trigger2') this.selectedButton = ''
        }).catch(error => {
          this.isDisable = false
          this.sendToastMsg('error', `${this.buttonItem.name} 장치가 응답하지 않았습니다.`)
        })   
    },

  },
  methods: {
    getCommandName (cmd) {
      switch (cmd) {
        case 'open': return '열림';
        case 'close': return '닫힘';
        case 'work': return '동작';
        default: return '정지';
      }
    },
    async getLogForm () {
      this.$refs.textLogForm.open()
    },

    /**
     * Toast Message
     * @param {String} msgType (success, info, error, warning)
     * @param {String} msgContents (message)
     */
    sendToastMsg(msgType, msgContents) {
      this.$toast(msgContents, {
          position: 'top-right',
          timeout: 1000,
          icon: true,
          closeOnClick: true,
          type: msgType,
          rtl: false
        })
    },

    getConnect() {
      //this.connect = this.iisConnect
      //console.log(this.iisConnect)
    },
    updateSettings() {
      this.$refs.automaticList.initSettings()
    },
    getDateString() {
      return getDateString(this.buttonItem.workingTime)
    },
    showModal() {
      this.$refs.modifyNameModal.show()
    },
    async updateData() {
      try {
        const res = await store.dispatch('button/fetchButton', { id: this.buttonItem._id })
        this.buttonItem = res.data
        this.$forceUpdate()
      } catch (error) {
        console.log(error)
      }

    },
    modifyName() {
      if(this.name.length > 12) {
        this.sendToastMsg('warning', `장치 이름이 너무 깁니다.`)
        this.$refs.modifyNameModal.show()
      }
      else {
        store.dispatch('button/updateButton',
        {
          id: this.buttonItem._id,
          queryBody: {
            name: this.name,
          },
        })
        .then(() => {
          this.updateData()
        })
        .catch(err => {
          console.log(err)
        })
      }  
    },
    toggleAlert() {
      this.isAlert = !this.isAlert
      this.alertIcon = this.isAlert ? 'BellIcon' : 'BellOffIcon'
      this.alertColor = this.isAlert ? 'text-warning' : 'text-musted'
    },
    async toggleAuto() {
      const settingList = await store.dispatch('button/fetchSettings', { id: this.buttonItem._id })
      const device = await store.dispatch('button/fetchButton', { id: this.buttonItem._id })
      if (settingList.data.autoSettings.length > 0 || ((device.data.isAuto && this.isAuto))) {
        this.isAuto = !this.isAuto
        this.autoColor = this.isAuto ? 'text-danger' : 'text-musted'
        this.isAutoColor = this.isAuto ? 'light-success' : 'light-dark'
        
        try {
          const res = await store.dispatch('button/updateButton', {
              id: this.buttonItem._id,
              queryBody: {
                isAuto: this.isAuto,
              }
          })
          if(res.data) {
            this.sendToastMsg('success', `${this.buttonItem.name} 자동화 ${(this.isAuto)? '등록': '해제'} 성공`)
            return this.updateData()
          } 
        } catch (error) {
          this.sendToastMsg('error', `${this.buttonItem.name} 자동화 ${(this.isAuto)? '등록': '해제'} 실패`)
        }
      }  
      else if (device.data.isAuto === false && this.isAuto) {
        this.isAuto = !this.isAuto
        this.autoColor = this.isAuto ? 'text-danger' : 'text-musted'
        this.isAutoColor = this.isAuto ? 'light-success' : 'light-dark'
      }
      else {
        const element = this.$createElement
        const title = element('b', {
          domProps: 
            { innerHTML: '자동화 등록이 필요합니다. <br> 등록하시겠습니까?'} 
          }
        )
        this.$bvModal.msgBoxConfirm([title], {
          title: '안내 메시지',
          titleTag: 'b',
          okTitle: '확인',
          okVariant: 'success',
          size: 'sm',
          buttonSize: 'sm',
          cancelTitle: '취소',
          hideHeaderClose: true,
          centered: true,
          }).then((ok) => {
              if(ok) this.$router.push({ name: 'remote-setting' })
              else this.sendToastMsg('warning', `자동화 등록 페이지에 접근할 수 없습니다.`)
          })
      }        
    },
    selectedWork(newVal) {   
      if(this.buttonItem.type === 'fan_trigger' 
       || this.buttonItem.type === 'feeder_1button' 
       || this.buttonItem.type === 'hydraulic_1trigger') {
        let timePer = 50
        let param = {
            command: newVal,
            commandValue: this.sliderValue,
          }
        store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
        .then(res => {
          if(res.data == true || res.data === 'success'){
            
            this.sendToastMsg('success', `${this.buttonItem.name} 동작하였습니다.`)
            this.updateData()
            
          } else this.sendToastMsg('error', `${this.buttonItem.name} 동작하지 못하였습니다.`)
        }).catch(error => {
          this.sendToastMsg('error', `${this.buttonItem.name} 장치가 응답하지 않았습니다.`)
        })
        this.selected = ''
      }      
    },
    isAutoFalse(length) {
      if (length === 0 ) {
        this.isAuto = !this.isAuto
        this.autoColor = this.isAuto ? 'text-danger' : 'text-musted'
        this.isAutoColor = this.isAuto ? 'light-success' : 'light-dark'
      }      
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
.box {
  margin: auto;
  transition: all 0.5s linear;
  display: block;
}

.hidden {
  display: hide;
}

.visuallyhidden {
  opacity: 0;
}
</style>
