<template>
    <div style="margin-top: 0.2rem; border: groove; border-radius: 0.5rem; background-color: #E7ECF4;">
        <h3>I o T 모터 제어기</h3>
        <table style="width: -webkit-fill-available;">
            <tr>
                
            </tr>
        </table>
    </div>
</template>

<script>
import { 
    BButtonGroup,
    BProgress,
    BButton,
    BTable,
    BSpinner,
    BFormSelect,
    BBadge,
    BInputGroup,
    BFormInput,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'

export default {
    components: {
        VueSlider,
        BProgress,
        BFormSelect,
        BSpinner,
        BButtonGroup,
        BButton,
        BTable,
        BBadge,
        BInputGroup,
        BFormInput,
        VueApexCharts: () => import('vue-apexcharts'),
    },
    props: {
        buttonItem: {
            type: Object,
            required: true,
        }
    },
    created() {
      this.init()
    },
    data() {
        return {
            
        }
    },
    methods: {
        async init() {
            
        },
    }
}

</script>
<style scoped>

</style>