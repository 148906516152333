<template>
  <div style="margin-top: 0.2rem; border: groove; border-radius: 0.5rem; background-color: #E7ECF4;">
    <!-- <h3>Digital Fan Control</h3>
    <br> -->
    <div v-if="state === null" class="mt-1">
        <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
            <b-spinner variant="warning" label="Spinning" class="mt-1"></b-spinner>
            <br><br>
            <b>잠시만 기다려주세요...</b>
        </h3>
    </div>
    <div v-if="state === false" class="mt-1">
        <h3 style="font-weight:bold; font-size: 1.2em;" class="mt-1">
            <h4>접속 불가</h4><hr>
            <feather-icon
                icon="AlertTriangleIcon"
                style="color:red;"
                size="45"/>
            <hr>
            <b>점검 사항</b>
            <hr>
            <p>1) 장비 전원</p>
            <p>2) 인터넷 연결</p>
            <p>3) 네트워크 라인</p>
        </h3>
        <br>
        <b-button variant="info" @click="init">재연결</b-button>
        <hr>
    </div>
    <div v-if="state === true" >
      <table style="width: -webkit-fill-available;">
        <tr>
          <td  style="width: 50%; ">
            <vue-apex-charts
              v-if="state"
              ref="charti"
              type="donut"
              height="150"
              :options="powerOptions"
              :series="powerSeries"
              />
          </td>
          <td style="width: 50%; ">
            <tr>
                <td colspan="2">
                    <p class="val">{{mode}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p class="title"> 현재 온도 : </p>
                </td>
                <td>
                    <p class="temperatureVal"> {{temperature}} ℃</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p class="title"> 설정 온도 : </p>
                </td>
                <td>
                    <p class="temperatureVal"> {{settingTemperature}} ℃</p>
                </td>
            </tr>
          </td>
        </tr>
      </table>
      <table style="width: -webkit-fill-available;">
        <tr>
          <td colspan="2">
            <b-button 
              style="width: 90%;"
              variant="info" 
              @click="refresh()">
              <feather-icon
                icon="RefreshCwIcon"
                style=""
                size="24"/>
            </b-button>
          </td>
        </tr>
        <br>
        <tr>
          <td>
            <b-button 
            style="width: 80%;"
              :variant="(mode==='온도 제어')? 'danger': 'outline-danger'"  
              :disabled="mode==='온도 제어'" 
              @click="btnKey('Temperature')">
              온도 제어
            </b-button>
          </td>
          <td>
            <b-button 
            style="width: 80%;"
              :variant="(mode==='수동 제어')? 'primary': 'outline-primary'"  
              :disabled="mode==='수동 제어'" 
              @click="btnKey('Manual')">
              수동 제어
            </b-button>
          </td>
        </tr>
        <br>
        <tr v-if="mode==='온도 제어'">
          <td>
            <b-button 
            class="btnStyle" 
            variant="primary" 
            size="lg" 
            @click="btnKey('Down')">
              ∨ 내림 
            </b-button>
          </td>
          <td>
            <b-button 
            class="btnStyle" 
            variant="danger" 
            size="lg"
            @click="btnKey('Up')">
              올림 ∧
            </b-button>
          </td>
            <!-- <td colspan="2">
                <h4>온도 조절</h4>
                <vue-slider
                    v-model="settingTemperature"
                    height="1.3rem"
                    :lazy="true"
                    :max="35"
                    :min="0"
                    :marks="[0, 5, 10, 15, 20, 25, 30, 35]"
                    :tooltip="'always'"
                    :interval="1"
                    :tooltip-formatter="`${settingTemperature} ℃`"
                    style="margin:2rem;"
                    :process-style="{background: '#E78989'}"
                    :tooltip-style="{background: '#E78989'}"
                    :dot-style="{background: '#E78989'}"
                    @change="changeVal('SettingTemp', settingTemperature)"
                  />
            </td> -->
        </tr>
        <tr v-else-if="mode==='수동 제어'">
          <td>
            <b-button 
            class="btnStyle" 
            variant="primary" 
            size="lg" 
            @click="btnKey('Down')">
              ∨ 내림 
            </b-button>
          </td>
          <td>
            <b-button 
            class="btnStyle" 
            variant="danger" 
            size="lg"
            @click="btnKey('Up')">
              올림 ∧
            </b-button>
          </td>
            <!-- <td colspan="2">
                <h4>출력 조절</h4>
                <vue-slider
                    v-model="nowRPM"
                    height="1.3rem"
                    :lazy="true"
                    :max="100"
                    :min="0"
                    :marks="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
                    :tooltip="'always'"
                    :interval="10"
                    :tooltip-formatter="`${nowRPM} ％`"
                    style="margin:2rem;"
                    @change="changeVal('ManuRPM', nowRPM)"
                  />
            </td> -->
        </tr>
        <tr v-else>
          <td colspan="2">
            <feather-icon
              icon="AlertTriangleIcon"
              style="color: orange;"
              size="25"/> <br>
            <b>"{{mode}}" 지원하지 않는 기능입니다.</b>
          </td>
        </tr>
        <hr>
      </table>
    </div>
  </div>
</template>

<script>
import { 
  BButtonGroup,
  BProgress,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BBadge,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'
export default {
  components: {
      VueSlider,
      BProgress,
      BFormSelect,
      BSpinner,
      BButtonGroup,
      BButton,
      BTable,
      BBadge,
      BInputGroup,
      BFormInput,
      VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
      buttonItem: {
          type: Object,
          required: true,
      }
  },
  created() {
    this.interval()
  },
  destroyed() {
    clearInterval(this.intervalId)
    clearTimeout(this.timeoutId)
  },
  data() {
      return {
        state: null,
        mode: null,
        nowRPM: 0,
        temperature: 0,
        settingTemperature: 0,
        powerOptions: {
            tooltip: {
                enabled : false
            },
            title: {
                text: '현재 출력'
            },
            noData: {
                text: 'No Data'
            },
            legend: {
                show: false
            },
            plotOptions: {
            pie: {
                size: '65%',
                customScale: 1,
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                        name: {
                          show: false,
                        },
                        value: {
                          fontSize: '2rem',
                          color: 'blue'
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            formatter: v => {
                                return `${((v.globals.seriesTotals[0]))}%`
                            }
                        }
                    }
                },
            }
            },
            colors: ['#4354FF', '#D5D5D5'],
            dataLabels: {
                enabled: false,
                formatter: v => v + " hz"
            },
            labels: [
                '현재 출력'
            ],
        },
        powerSeries: [0, 100],
        chartReady: false,
        intervalId: '',
        timeoutId: ''
      }
  },
  async mounted() {
    await this.init()
    this.chartReady = true
  },
  methods: {
      interval () {
        /* clearInterval(this.intervalId)
        this.intervalId = setInterval(() => {
          this.refresh()
        }, 1000 * 5);

        clearTimeout(this.timeoutId)

        this.timeoutId = setTimeout(() => {
          clearInterval(this.intervalId)
        }, 1000 * 10); */
      },
      async init() {
        this.state = null;
        return this.refresh()
       
      },
      async btnKey(mode, next) {
        //this.state = null
        let commandValue
        if(this.mode === '수동 제어') commandValue = (mode === 'Down')? this.nowRPM - 10: this.nowRPM + 10
        else commandValue = (mode === 'Down')? this.settingTemperature - 1: this.settingTemperature + 1
        const param = {command: `setkey${mode}`, commandValue}
        await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
        if(next) return
        return this.refresh()
      },
      async changeVal(target, val) {
        const param = {
          command: `set${target}`,
          commandValue: val
        }
        await store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
        return this.refresh()
      },
      async refresh() {
        const res = await store.dispatch('button/getState', { id: this.buttonItem._id });
        if (res.data.status === 'success') {
          this.state = true;
          this.mode = res.data.data.mode;
          this.nowRPM = res.data.data.nowRPM;
          this.temperature = res.data.data.temperature;
          this.settingTemperature = res.data.data.settingTemperature;
          this.powerSeries = [this.nowRPM, 100 - this.nowRPM]
          return this.state = true;
        }
      }
  },  
}

</script>
<style scoped>
p.title {
  font-size: larger;
  color:black;
  text-align: center;
  font-weight: bold;
}
p.val {
  font-size: larger;
  color:blue;
  text-align: center;
  font-weight: bold;
}
p.temperatureVal {
  font-size: larger;
  color:red;
  text-align: center;
  font-weight: bold;
}

.btnStyle {
  border: 2px outset;
  width: 80%;
}
</style>