<template>
    <div>
        <b-modal
            ref="logList"
            ok-title="닫기"
            ok-variant="secondary"
            hide-header
            header-class="p-1 custom-modal-title modal-title"
            no-stacking
            ok-only
            centered>
            <h4 style="text-align: center;">{{ buttonItem.name }} 사용기록</h4>
            <div >
                <!-- <h4 style="text-align:center">"{{ buttonItem.name }}" 사용 기록</h4> -->
                <label for="Log-date-picker" >▽ 날짜를 선택해주세요 ▽</label>
                <b-form-datepicker 
                    hide-header
                    id="Log-date-picker" 
                    v-model="selectDate" 
                    label-help="상단 화살표로 년, 월을 선택할 수 있습니다.."
                    size="lg"
                    :max="`${today}`"
                    class="mb-2">
                </b-form-datepicker>
                <b-table 
                    show-empty
                    sticky-header
                    empty-text="데이터가 없습니다"
                    responsive
                    small
                    striped
                    borderless
                    table-variant="primary"
                    sort-by="date"
                    sort-desc
                    :items="btnLogs.data" 
                    :fields="[
                        { key: 'orderer', label: '모드', thStyle: 'font-size: 1rem; color: black;', sortable: true},
                        { key: 'command', label: '명령', thStyle: 'font-size: 1rem; color: black;', sortable: false},
                        { key: 'result', label: '결과', thStyle: 'font-size: 1rem; color: black;', sortable: true},
                        { key: 'date', label: '시간', thStyle: 'font-size: 1rem; color: black;', sortable: true},
                    ]" 
                    style="
                        text-align:center;
                        color: red; 
                        border: ridge; 
                        border-radius: 0.5rem; 
                        font-size: 1rem; 
                        font-weight:bold" 
                        >
                    
                    <template #cell(orderer)="row">
                        <div>
                            {{(row.item.orderer === 'User')? '수동': '자동'}}
                        </div>
                    </template>
    
                    <template #cell(command)="row">
                        <div>
                            {{ getCommandName(row.item.command) }}
                        </div>
                    </template>

                    <template #cell(result)="row">
                        <div>
                            <feather-icon
                                size="20"
                                :icon="`${(row.item.result === 'true')? 'CheckIcon': 'XIcon'}`"
                                :style="`color: ${(row.item.result === 'true')? 'blue': 'red'}`">
                            </feather-icon>
                        </div>
                    </template>
                    <template #cell(date)="row">
                        <div>
                            {{ getTime(row.item.date) }}
                        </div>
                    </template>
                </b-table>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal, BTable, BFormDatepicker  } from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
    name: 'TextLogForm',
    components: {
        BModal,
        BTable, 
        BFormDatepicker,
        FeatherIcon,
        
    },
    props: {
        buttonItem: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            today: moment().format('YYYY-MM-DD'),
            btnLogs: [],
            selectDate: ''
        }
    },
    created() {
        
    },
    mounted() {
    },
    watch: {
        selectDate() {
            this.findBtnLogs()
        }
    },
    methods: {
        async open() {
            await this.findBtnLogs()
        },
        async findBtnLogs () {
            if(this.selectDate === '') return this.selectDate = this.today
            this.btnLogs = await store.dispatch('button/getBtnLogListByDate', { buttonId: this.buttonItem._id, date: this.selectDate })
            return this.$refs.logList.show()
            //this.$refs.btnLogList.show() 
        },
        getCommandName (cmd) {
            switch (cmd) {
                case 'open': return '열림';
                case 'close': return '닫힘';
                case 'work': return '동작';
                default: return '정지';
            }
        },
        getTime(date) {
            return `${date.substring(5, 16)}`
        }
    },
}
</script>

<style scoped>
.custom-modal-title .modal-title {
    font-size: 2em;
}
.text-center {
  text-align: center;
}
</style>